import channelUpdation from "./HomeStore/channelUpdation";
import userChannelOperation from "./HomeStore/userChannelOperation";
import channelSupport from "./HomeStore/channelSupport";
import channelUtility from "./HomeStore/channelUtility";
import router from "@/router";
import axios from "axios";
import debug from "@/console";

const state = {};
const getters = {
  /**
   * this getter will return previous selected teams and their selected channels
   */
  getPrevTeamsSelectChannel: (state, getters, rootState) =>
    rootState.prevTeamsSelectChannel,
  getPlatform: (state, getters, rootState) =>
    JSON.parse(localStorage.getItem("platform"))
      ? JSON.parse(localStorage.getItem("platform"))
      : "web",
  /**
   * this getter will return token if channel is not connect otherwise it return secret key
   */
  getFileCompletePath: (state, getters, rootState, rootGetters) => (path) => {
    let generatedURL = path.split("/");
    let tokenIndex =
      generatedURL.length == 7
        ? generatedURL.length - 2
        : generatedURL.length - 1;
    generatedURL.splice(
      tokenIndex,
      0,
      !rootGetters.getSelectedChannel.invited
        ? rootGetters.getStoreData.token
        : rootGetters.getSelectedChannel.secret_key
    );
    return generatedURL.join("/");
  },
};
const actions = {
  getOnlineUsers(context, data) {
    this._vm.$socket.client.emit("getOnlineUsers", data);
  },
  /**
   * This action is responsible for getting new data on page refresh or on internet working
   * args:(context)
   */
  getUserData(context) {
    let token = JSON.parse(localStorage.getItem("token"));
    let customAxios = axios.create({
      headers: {
        Token: token,
      },
    });
    try {
      return new Promise((resolve, reject) => {
        customAxios
          .post(process.env.VUE_APP_BASE_URL + "/getUserData")
          .then((response) => {
            try {
              resolve(response);
              //store previous displayed support channels
              context.commit(
                "updatePreDispChannels",
                JSON.parse(
                  JSON.stringify(
                    context.rootGetters.getSelTeamJoinedChannels.filter(
                      (channel) => channel.is_displayed
                    )
                  )
                )
              );
              //store previous displayed direct channels
              context.commit(
                "saveDisplayDirectChannels",
                JSON.parse(
                  JSON.stringify(
                    context.rootGetters.getCompanyDirectChannels.filter(
                      (channel) => channel.is_displayed
                    )
                  )
                )
              );
              if (response.data && response.data.version) {
                context.dispatch("checkAppVersion", response.data.version);
              }
              //update new data
              context.commit("replaceNewStoreData", {
                preStoreData: context.rootState.storeData,
                newStoreData: response.data,
              });
              //to get count of cloud channels
              context.dispatch("getCloudChannelsMsgsCount", {
                page: 1,
                channel_id: context.rootGetters.getSelectedChannel?._id,
              });
              //send data to socket for authentication
              let selected_company = context.rootGetters.getSelectedCompany._id;
              this._vm.$socket.client.emit("authenticate", {
                token: response.data.token,
                selected_company: selected_company,
                status: context.rootGetters.getLoggedInUserDetail.status,
                is_remove_messages: true,
                company_ids: context.rootGetters.getCompaniesIds,
                version: localStorage.getItem("App_Version"),
              });
              //restore direct channels after new data
              context.commit("restoreChannelsLimitation", {
                preDisplayedChannels: context.rootGetters.getPreDispChannels,
                joinedChannels: context.rootGetters.getSelTeamJoinedChannels,
                nonJoinedChannels: [],
              });
              //restore direct channels after new data
              context.commit("restoreChannelsLimitation", {
                preDisplayedChannels: context.state.prevDispDirectChannels,
                joinedChannels: context.rootGetters.getDirectChannels,
                nonJoinedChannels: context.rootGetters.getNotDirectChannels,
              });
              //select team if user get team on page refresh in case of ip block
              if (context.rootGetters.getSelectedTeam == undefined) {
                context.dispatch("selectTeamAfterUserData");
              }
              //remove new messsage count of selected channel
              setTimeout(() => {
                context.dispatch("clearMessageRead");
              }, 1500);
              //get channels messages count
              context.dispatch("channelsPolling");
            } catch {
              context.dispatch("logOut");
            }
          })
          .catch(async (error) => {
            try {
              if (
                error.response &&
                error.response.data &&
                error.response.data.version
              ) {
                context.dispatch(
                  "checkAppVersion",
                  error.response.data.version
                );
              }
              // error occured
              if (error.response && error.response.status == 401) {
                context.dispatch("logOut");
              }
              reject(error);
            } catch {
              context.dispatch("logOut");
            }
          });
      });
    } catch {
      context.dispatch("logOut");
    }
  },
  /**
   * this action is responsible for adding previous state of selected team and it's channel
   * args: (payload) payload = {teamId, channel}
   */
  savePrevTeamSelectChannel({ commit }, payload) {
    //first check if a team state is already in array then remove previous and add new one
    commit("checkAndRmvPreTeamsChannel", payload.teamId);
    commit("updatePreTeamsChannel", payload);
  },
  /**
   * this action is responsible for selecting team after page refresh (in ip block)
   * args: ()
   */
  selectTeamAfterUserData({ dispatch, rootGetters }) {
    let teamDetail = rootGetters.getSelectedCompany.teams.find(
      (team) => team.joined && team.archived == null
    );
    if (teamDetail) {
      dispatch("selectTeam", teamDetail._id);
    }
  },

  frontendWebhooks({ commit }, payload) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    debug.log(payload, process.env.VUE_APP_WEBHOOKS_URL);

    axios
      .post(process.env.VUE_APP_WEBHOOKS_URL, payload, config)
      .then(() => {
        // console.log(response);
      })
      .catch(() => {});
  },
  // this function is responsible for getting gifs data
  getGiphyData({ commit }, payload) {
    let token = JSON.parse(localStorage.getItem("token"));
    let customAxios = axios.create({
      headers: {
        Token: token,
      },
    });
    return new Promise((resolve, reject) => {
      customAxios
        .post(process.env.VUE_APP_BASE_URL + "/getGifsData", payload)
        .then((response) => {
          resolve(response);
        })
        .catch(async (error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
    //get new store data
    replaceNewStoreData: (state, payload) => {
      Object.assign(payload.preStoreData, payload.newStoreData);
    },
    saveDisplayDirectChannels: (state, payload) => {
      state.prevDispDirectChannels = payload;
    },
    //restore direct channels which were displayed before page refresh
    restoreChannelsLimitation: (state, payload) => {
      payload.joinedChannels.forEach((channel) => {
        if (
          channel.new_message_count > 0 ||
          (channel.thread_child_messages &&
            channel.thread_child_messages.length > 0)
        ) {
          channel.is_displayed = true;
        }
      });
      payload.preDisplayedChannels.forEach((prevChannel) => {
        //first find in direct channels and make their property is_display to true
        payload.joinedChannels.forEach((channel) => {
          if (prevChannel._id == channel._id) {
            channel.is_displayed = true;
          }
        });
        //secondly find in non direct channels and make their property is_display to true
        payload.nonJoinedChannels.forEach((channel) => {
          if (prevChannel._id == channel._id) {
            channel.is_displayed = true;
          }
        });
      });
    },
  },
  modules = {
    channelUpdation,
    userChannelOperation,
    channelSupport,
    channelUtility,
  };

export default {
  state,
  getters,
  mutations,
  actions,
  modules,
};
