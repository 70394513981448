var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],ref:_vm.dragHandleId,staticClass:" rounded-lg audio-tray pt-0 pb-3"},[_c('div',{staticClass:"row header-class rounded-lg rounded-b-0 d-flex justify-space-between mt-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","x-small":"","color":"white"},on:{"click":_vm.onClickGotoChannel,"touchstart":_vm.onClickGotoChannel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v("Go Back")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","x-small":"","color":"white"},on:{"click":_vm.audioClose,"touchstart":_vm.audioClose}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Close")])])],1),_c('div',{staticClass:"row justify-center "},[_c('p',[_vm._v(_vm._s(this.Urlpath.audioUserName.name))])]),_c('div',{staticClass:"d-flex"},[_c('audio',{ref:"headerAudio",staticClass:"px-0 a-audio mb-1",class:{
        'medium-display': _vm.$vuetify.breakpoint.mdAndUp,
        'small-display': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"controls":"","autoplay":""},on:{"ended":function($event){return _vm.onEnded()},"pause":function($event){return _vm.pasueLisner()},"play":function($event){return _vm.playLisner()}}},[_c('source',{attrs:{"src":_vm.Urlpath.url,"type":"audio/mp3"}}),_vm._v(" Your browser does not support the audio element. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }