/**
 * System: Whistle It
 * Developer: Nabil Ahmad and Saud Saleem
 * Organization: Whistle It
 * Purpose: This file is for  test   listening and changing updated data for channel.
 */
import debug from "@/console";
var channelSocket = {
  state: {},
  getters: {
    getIndex: (state, getters, rootState) => async (data) => {
      try {
        let companies = rootState.storeData.companies;
        //store company index in which channel is created
        let companyIndex = await companies.findIndex(
          (comapny) => comapny._id == data.company_id
        );

        //store team index in which channel is created
        let teamIndex = -1,
          channelIndex = -1;
        //if company is find
        if (companyIndex > -1) {
          if (data.type != "direct" && data.type != "group") {
            teamIndex = await companies[companyIndex].teams.findIndex(
              (team) => team._id == data.team_id
            );
            channelIndex = await companies[companyIndex].channels.findIndex(
              (channel) =>
                (channel.name.toLowerCase() ==
                  data.channel.name.toLowerCase() ||
                  channel._id == data.channel._id) &&
                ((data.type != "support" &&
                  channel.team_id ==
                    companies[companyIndex].teams[teamIndex]._id) ||
                  data.type == "support")
            );
          } else {
            channelIndex = await companies[companyIndex].direct.findIndex(
              (channel) =>
                (data.channel.users &&
                  data.channel.users.length == channel.users.length &&
                  channel.users.filter(
                    (channelUserId) =>
                      !data.channel.users.includes(channelUserId)
                  ).length == 0 &&
                  ((!channel.hosted && !channel.invited) ||
                    ((channel.hosted || channel.invited) &&
                      channel.user_id == data.channel.user_id))) ||
                channel._id == data.channel._id
            );
          }
        }
        return {
          companies,
          companyIndex,
          teamIndex,
          channelIndex,
        };
      } catch (err) {
        return {
          companies: [],
          companyIndex: -1,
          teamIndex: -1,
          channelIndex: -1,
        };
      }
    },
    getChannelById: (state, getters, rootState) => (
      companyId,
      teamId,
      channelId,
      channelType
    ) => {
      let companies = rootState.storeData.companies;
      let company = companies.find((company) => company._id == companyId);
      let channel;
      if (channelType != "direct" && channelType != "group") {
        channel = company.channels.find(
          (channel) => channel._id == channelId && channel.team_id == teamId
        );
      } else {
        channel = company.direct.find((channel) => channel._id == channelId);
      }
      return channel;
    },
  },
  actions: {
    /**
     * This socket listener recieve data from socket whenever a new channel is created
     * User who created channel recieve this listener
     * args:(context, data)
     */

    async socket_newChannel(context, data) {
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (data.type == "direct") {
        //check new direct channel is display or not
        context.commit("displayDirectChannel", {
          nonDirectChannels: context.rootGetters.getNotDirectChannels,
          loginUserId: loginUserId,
          channelIndex: store.channelIndex,
          data,
        });
      }
      let isGroupChannel = false;
      //if channel type is group then set it to direct
      if (data.type == "group") {
        isGroupChannel = true;
        data.type = "direct";
      } else if (data.type != "direct") {
        data.type = "channels";
      }
      //add that channel to store data
      context.commit("newChannel", {
        storeData: store.companies[store.companyIndex],
        loginUserId: loginUserId,
        channelIndex: store.channelIndex,
        data,
        isGroupChannel,
      });
    },
    /**
     * This socket listener recieve data from socket whenever a new public channel is created
     * All users of that team will recieve this listener
     * args:(context, data)
     */

    async socket_newPublicChannel(context, data) {
      // get login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (store.companyIndex > -1 && store.teamIndex > -1) {
        //add that channel to store data
        context.commit("newPublicChannel", {
          storeData: store.companies[store.companyIndex],
          teamData: store.companies[store.companyIndex].teams[store.teamIndex],
          loginUserId: loginUserId,
          channelIndex: store.channelIndex,
          data,
        });
      }
    },

    /**
     * This socket listener recieve data from socket whenever a new direct channel is created
     * The other user will recieve this listener
     * args:(context, data)
     */
    async socket_newDirectChannel(context, data) {
      debug.log("new direct channel created:", data);
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (store.channelIndex == -1) {
        //check new direct channel is display or not
        context.commit("displayDirectChannel", {
          nonDirectChannels: context.rootGetters.getNotDirectChannels,
          loginUserId: loginUserId,
          data,
        });
        //add that channel to store
        context.commit("newDirectChannel", {
          storeData: store.companies[store.companyIndex].direct,
          data,
        });
      }
      debug.log("newDirectChannelCreated:", data);
    },

    /**
     * This socket listener is responsible for providing data to  all other users that a member is added
     * in channel
     * All users of that channel recieve this listener
     * args:(context, data)
     */
    async socket_userAddedInChannel(context, data) {
      debug.log("user add", data);
      // add key so that we use our getIndex getter
      data.channel = {
        _id: data.channel_id,
        name: data.channel_name,
      };
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      debug.log("new user added in channel:", data);
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //if channel is find
      if (store.channelIndex != -1) {
        let storeData = null;
        if (data.type == "group") {
          storeData =
            store.companies[store.companyIndex].direct[store.channelIndex];
        } else {
          storeData =
            store.companies[store.companyIndex].channels[store.channelIndex];
        }
        //add that user to specific channel
        context.commit("userAddedInChannel", {
          storeData,
          loginUserId: loginUserId,
          data,
        });

        debug.log("user Added In Channel:", data);
      }
    },

    /**
     * This socket listener is responsible for providing data to specific user that you are added in channel(private)
     * User who added in channel will recieve this listener
     * args:(context, data)
     */
    async socket_addedInChannel(context, data) {
      debug.log("user add", data);
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      context.commit("addUserInPrivateChannel", {
        storeData: store.companies[store.companyIndex].channels,
        channelIndex: store.channelIndex,
        data,
      });

      debug.log("Added In Channel:", data);
    },

    /**
     * This socket listener is responsible for removing specific user from channel(private)
     * The user who removed from channel will recieve this listener
     * args:(context, data)
     */
    async socket_removedChannel(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      let selectedChannelId = await context.rootState.selectedChannel._id;
      //if user already on that selected from which it is removed than
      //clear that channel
      if (selectedChannelId == data.channel._id) {
        //find default channel of that user
        let selectedChannel = await context.rootGetters.getSelectedCompany.channels.find(
          (channel) =>
            channel.default == true &&
            channel.team_id == context.rootGetters.getSelectedTeam._id
        );
        //select default channel
        context.dispatch("selectChannel", {
          _id: selectedChannel._id,
          type: selectedChannel.type,
        });
      }
      //if channel is find
      if (store.channelIndex != -1) {
        let storeData = null;
        if (data.type == "group") {
          storeData = store.companies[store.companyIndex].direct;
        } else {
          storeData = store.companies[store.companyIndex].channels;
        }
        //remove that private channel
        context.commit("removeFromPrivateChannel", {
          storeData,
          index: store.channelIndex,
          data,
        });
      }
    },

    /**
     * This socket listener is responsible for providing data to other users that specific user is removed from channel
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_usersRemovedFromChannel(context, data) {
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //find selected Channel
      let selectedChannelId = await context.rootState.selectedChannel._id;
      //if user already on that selected from which it is removed than
      //clear that channel
      if (
        selectedChannelId == data.channel._id &&
        data.user_ids.includes(loginUserId)
      ) {
        //find default channel of that user
        let selectedChannel = await context.rootGetters.getSelectedCompany.channels.find(
          (channel) =>
            channel.team_id == context.rootGetters.getSelectedTeam._id &&
            channel.default == true
        );
        //select default channel
        context.dispatch("selectChannel", {
          _id: selectedChannel._id,
          type: selectedChannel.type,
        });
      }
      //if channel is find
      if (store.channelIndex != -1) {
        let storeData = null;
        if (data.type == "group") {
          storeData =
            store.companies[store.companyIndex].direct[store.channelIndex];
        } else {
          storeData =
            store.companies[store.companyIndex].channels[store.channelIndex];
        }
        // remove that user from all other users channel array
        context.commit("removeUserFromChannel", {
          storeData,
          loginUserId: loginUserId,
          data,
        });
        debug.log("usersRemovedFromChannel:", data);
      }
    },
    /**
     * This socket listener is recieve to a user who leave channel (i.e if user is login on another device)
     * args:(context, data)
     */
    async socket_channelLeft(context, data) {
      debug.log("user left channel", data);

      // add key so that we use our getIndex getter
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      let selectedChannelId = await context.rootState.selectedChannel._id;
      if (store.channelIndex != -1) {
        //if user already on that selected from which it is removed than select announcement channel
        if (
          selectedChannelId == data.channel._id &&
          data.user_ids.includes(loginUserId)
        ) {
          //find default channel of that user
          let selectedChannel = await context.rootGetters.getSelectedCompany.channels.find(
            (channel) =>
              channel.team_id == context.rootGetters.getSelectedTeam._id &&
              channel.default == true
          );
          //select default channel
          context.dispatch("selectChannel", {
            _id: selectedChannel._id,
            type: selectedChannel.type,
          });
        }
        // remove that user from channel users array
        context.commit("channelLeft", {
          storeData: store.companies[store.companyIndex].channels,
          index: store.channelIndex,
          loginUserId: loginUserId,
          data,
        });
      }
    },
    /**
     * This socket listener is responsible for providing new name of channel to all users of that channel
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_channelNameUpdate(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //if channel type is group then set it to direct
      //if channel type is group then set it to direct
      if (data.type == "group" || data.type == "direct") {
        data.type = "direct";
      } else {
        data.type = "channels";
      }
      if (store.channelIndex != -1) {
        // update that channel name
        context.commit("channelNameUpdated", {
          storeData:
            store.companies[store.companyIndex][data.type][store.channelIndex],
          data,
        });
      }
    },
    /**
     * This socket listener is responsible for providing new description of channel to all users of that channel
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_channelDescriptionUpdated(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //update channel description
      context.commit("channelDescriptionUpdated", {
        storeData:
          store.companies[store.companyIndex].channels[store.channelIndex],
        data,
      });
    },
    /**
     * This socket listener is responsible for providing new creator of channel to all users of that channel
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_channelCreatorUpdate(context, data) {
      debug.log("channel creator updated:", data);
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (store.channelIndex != -1) {
        // update that channel creator
        context.commit("channelCreatorUpdated", {
          storeData:
            store.companies[store.companyIndex].channels[store.channelIndex],
          data,
          loginUserId: context.rootGetters.loggedInUserDetails._id,
        });
      }
    },
    /**
     * This socket listener is responsible for providing all users that channel is archived
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_channelArchived(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      // login user id
      let loginUserId = context.rootGetters.loggedInUserDetails._id;
      //selected channel id
      if (context.rootState.selectedChannel != null) {
        var selectedChannelId = context.rootState.selectedChannel._id;
        //find default channel
        if (selectedChannelId == data.channel._id) {
          let selectedChannel = await context.rootGetters.getSelectedCompany.channels.find(
            (channel) =>
              channel.team_id == context.rootGetters.getSelectedTeam._id &&
              channel.default == true
          );

          await context.dispatch("selectChannel", {
            _id: selectedChannel._id,
            type: selectedChannel.type,
          });
        }
      }
      if (store.companyIndex > -1) {
        //archive that channel
        context.commit("channelArchived", {
          storeData: store.companies[store.companyIndex].channels,
          loginUserId: loginUserId,
          index: store.channelIndex,
          data,
        });
      }
    },
    /**
     * This action is responsible for unarchive public or private channel (to channel members and admin/subadmin)
     * args:(context, Company_id,Team_id,Type,Channel object)
     */
    async socket_channelUnArchived(context, data) {
      //find indexes of company, team and channel on the basis of ids
      let oldChannelDetail = await context.getters.getIndex(data);
      if (
        data.channel.users.includes(context.rootGetters.loggedInUserDetails._id)
      ) {
        //check if channel is already exist
        if (oldChannelDetail && oldChannelDetail.channelIndex > -1) {
          //update channel object
          context.commit("updateChannelUnarchived", {
            previousChannel:
              oldChannelDetail.companies[oldChannelDetail.companyIndex]
                .channels[oldChannelDetail.channelIndex],
            newChannel: data.channel,
          });
        } else {
          //add new channel
          context.commit("addChannelUnarchived", {
            channels:
              context.rootState.storeData.companies[
                oldChannelDetail.companyIndex
              ].channels,
            newChannel: data.channel,
          });
        }
      }
    },

    /**
     * This action is responsible for unarchive public channel (i.e. to all team members)
     * args:(context, data)
     */
    async socket_publicChannelUnArchived(context, data) {
      //find indexes of company, team and channel on the basis of ids
      let oldChannelDetail = await context.getters.getIndex(data);
      //check if channel is already exist
      if (oldChannelDetail.channelIndex == -1) {
        //add new channel
        context.commit("addChannelUnarchived", {
          channels:
            oldChannelDetail.companies[oldChannelDetail.companyIndex].channels,
          newChannel: data.channel,
        });
      }
    },
    /**
     * This socket listener is responsible for providing muted channel data to that user
     * User who mute channel will recieve that emit
     * args:(context, data)s
     */
    async socket_channelMuted(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //if channel type is group then set it to direct
      if (data.type == "group" || data.type == "direct") {
        data.type = "direct";
      } else {
        data.type = "channels";
      }
      //mute that channel
      context.commit("channelMute", {
        storeData:
          store.companies[store.companyIndex][data.type][store.channelIndex],
        data,
      });
    },
    /**
     * This socket emit is responsible for providing channel flag status if a loggedin user is active on another device
     * args:(context, data)
     */
    async socket_channelFlagged(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //if channel type is group then set it to direct
      if (data.type == "group" || data.type == "direct") {
        data.type = "direct";
      } else {
        data.type = "channels";
      }
      //mute that channel
      context.commit("channelFlag", {
        storeData:
          store.companies[store.companyIndex][data.type][store.channelIndex],
        flagged: data.flagged,
      });
    },
    /**
     * This socket emit is responsible for providing new direct channels
     * args:(context, data)
     */
    async socket_directChannelActivated(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (store.channelIndex == -1) {
        //mute that channel
        context.commit("addDirectChannelInCompany", {
          channels: store.companies[store.companyIndex][data.type],
          newChannelData: data.channel,
        });
      }
    },
    /**
     * This socket emit is responsible for removing direct channels
     * args:(context, data)
     */
    async socket_directChannelDeactivated(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      if (
        context.rootState.selectedChannel &&
        data.channel._id == context.rootState.selectedChannel._id &&
        context.rootGetters.getSelectedTeam
      ) {
        //find default channel of that user
        let selectedChannel = await context.rootGetters.getSelectedCompany.channels.find(
          (channel) =>
            channel.team_id == context.rootGetters.getSelectedTeam._id &&
            channel.default == true
        );
        //select default channel
        if (selectedChannel) {
          context.dispatch("selectChannel", {
            _id: selectedChannel._id,
            type: selectedChannel.type,
          });
        }
      }
      if (store.channelIndex > -1) {
        //mute that channel
        context.commit("removeDirectChannelFromCompany", {
          channels: store.companies[store.companyIndex][data.type],
          channelIndex: store.channelIndex,
        });
      }
    },
    /**
     * This socket listener recieve data from socket whenever a new channel is created
     * User who created channel recieve this listener
     * args:(context, data)
     */
    async socket_channelReadOnlyChanged(context, data) {
      debug.log("this is data ", data);
      let channelDetail = await context.getters.getIndex(data);
      if (channelDetail.channelIndex > -1) {
        context.commit("changeIsReadOnly", {
          channelposition:
            channelDetail.companies[channelDetail.companyIndex].channels[
              channelDetail.channelIndex
            ],
          allUsersRestricted: data.all_users_restricted,
          isReadOnly: data.is_read_only,
        });
      }
    },
    //creator sockets
    async socket_allowedSenderAdded(context, data) {
      debug.log("this is Adata", data);
      let channelDetail = await context.getters.getIndex(data);
      if (channelDetail.channelIndex > -1) {
        context.commit("allowedSenderAdded", {
          channelposition:
            channelDetail.companies[channelDetail.companyIndex].channels[
              channelDetail.channelIndex
            ],
          allowed_users: data.user_ids,
          allUsersRestricted: data.all_users_restricted,
        });
      }
    },
    async socket_allowedSenderRemoved(context, data) {
      debug.log("this is Rdata", data);
      let channelDetail = await context.getters.getIndex(data);
      if (channelDetail.channelIndex > -1) {
        context.commit("allowedSenderRemoved", {
          channelposition:
            channelDetail.companies[channelDetail.companyIndex].channels[
              channelDetail.channelIndex
            ],
          removed_sender: data.user_ids,
          allUsersRestricted: data.all_users_restricted,
        });
      }
    },
    // //user sockets
    async socket_removedAllowedSender(context, data) {
      let channelDetail = await context.getters.getIndex(data);
      if (channelDetail.channelIndex > -1) {
        context.commit("removedAllowedSender", {
          channelposition:
            channelDetail.companies[channelDetail.companyIndex].channels[
              channelDetail.channelIndex
            ],
        });
      }
    },

    async socket_addedAllowedSender(context, data) {
      let channelDetail = await context.getters.getIndex(data);
      if (channelDetail.channelIndex > -1) {
        context.commit("addedAllowedSender", {
          channelposition:
            channelDetail.companies[channelDetail.companyIndex].channels[
              channelDetail.channelIndex
            ],
        });
      }
    },
    /**
     * This socket listener is responsible for updating cloud channel
     * All users of that channel will recieve this listener
     * args:(context, data)
     */
    async socket_cloudChannelUpdate(context, data) {
      // find indexes of channel, company & team
      let store = await context.getters.getIndex(data);
      //if channel found
      if (store.channelIndex != -1) {
        // update channel cloud info
        context.commit("cloudChannelUpdate", {
          channel:
            store.companies[store.companyIndex].channels[store.channelIndex],
          newData: data.channel,
        });
      }
    },
  },

  mutations: {
    /**
     * This mutation will add update channel after unarchived
     * args:(state, payload)
     */
    updateChannelUnarchived(state, payload) {
      Object.assign(payload.previousChannel, payload.newChannel);
    },
    /**
     * This mutation will add new channel after unarchived
     * args:(state, payload)
     */
    addChannelUnarchived(state, payload) {
      payload.channels.push(payload.newChannel);
    },
    /**
     * This mutation will add new channel
     * args:(state, payload)
     */
    newChannel(state, payload) {
      // if channel already exist remove it
      if (payload.channelIndex != -1) {
        payload.storeData[payload.data.type].splice(payload.channelIndex, 1);
      }
      // if login and creator are same then push that channel
      if (
        payload.loginUserId == payload.data.channel.creator_id ||
        payload.isGroupChannel
      ) {
        payload.storeData[payload.data.type].unshift(payload.data.channel);
      }
    },
    /**
     * This mutation will add new public channel
     * args:(state, payload)
     */
    newPublicChannel(state, payload) {
      if (payload?.teamData?.joined) {
        //if channel index not found and login user is not created
        if (
          payload.channelIndex == -1 &&
          payload.loginUserId != payload.data.channel.creator_id
        ) {
          // push that channel
          payload.storeData.channels.unshift(payload.data.channel);
        }
      }
    },
    /**
     * This mutation will change is_displayed key of direct channel if channel is already displayed
     * args:(state, payload)
     */
    displayDirectChannel(state, payload) {
      let channel;
      if (payload.data.channel.users.length > 1) {
        channel = payload.nonDirectChannels.find(
          (channel) =>
            channel._id != payload.loginUserId &&
            payload.data.channel.users.includes(channel._id)
        );
        if (channel && channel.is_displayed) {
          payload.data.channel.is_displayed = true;
        }
      } else {
        channel = payload.nonDirectChannels.find(
          (channel) => channel._id == payload.loginUserId
        );
        if (channel && channel.is_displayed) {
          payload.data.channel.is_displayed = true;
        }
      }
    },
    /**
     * This mutation will add new direct channel
     * args:(state, payload)
     */
    newDirectChannel(state, payload) {
      //push that channel
      payload.storeData.unshift(payload.data.channel);
    },
    /**
     * This mutation will join public channel
     * args:(state, payload)
     */
    userAddedInChannel(state, payload) {
      debug.log("aded in channel:", payload);
      //if type public and joined false and added user contain user id then set join true
      if (
        payload.storeData.type == "public" &&
        payload.storeData.joined == false &&
        payload.data.user_ids.includes(payload.loginUserId)
      ) {
        payload.storeData.joined = true;
      }
      payload.data.user_ids.forEach((userId) => {
        if (!payload.storeData.users.includes(userId)) {
          payload.storeData.users.push(userId);
        }
      });
      //update the group channel name if member added
      if (payload.storeData.type == "group" && payload.data?.channel_name) {
        payload.storeData.name = payload.data.channel_name;
      }
    },
    /**
     * This mutation will add user in private channel
     * args:(state, payload)
     */
    addUserInPrivateChannel(state, payload) {
      if (payload.channelIndex > -1) {
        if (!payload.storeData[payload.channelIndex].joined) {
          Object.assign(
            payload.storeData[payload.channelIndex],
            payload.data.channel
          );
        }
      } else {
        payload.storeData.unshift(payload.data.channel);
      }
    },
    /**
     * This mutation will remove user from private channel
     * args:(state, payload)
     */
    removeFromPrivateChannel(state, payload) {
      if (payload.index != -1) {
        if (payload.data.type == "public") {
          payload.storeData[payload.index].joined = false;
          payload.storeData[payload.index].messages = [];
        } else {
          payload.storeData.splice(payload.index, 1);
        }
      }
    },
    /**
     * This mutation will remove user from private channel
     * args:(state, payload)
     */
    removeUserFromChannel(state, payload) {
      //if type public and joined false and removed user contain login user id then set join false
      debug.log("remove form usr", payload);
      if (
        payload.storeData.type == "public" &&
        payload.storeData.joined == true &&
        payload.data.user_ids.includes(payload.loginUserId)
      ) {
        payload.storeData.joined = false;
        payload.storeData.messages = [];
      }
      //match both arrays and then remove that users from store
      payload.storeData.users = payload.storeData.users.filter(
        (element) => !payload.data.user_ids.includes(element)
      );
      //update the group channel name on member removal
      if (payload.storeData.type == "group" && payload.data?.channel?.name) {
        payload.storeData.name = payload.data.channel.name;
      }
    },
    /**
     * This mutation will remove that channel
     * args:(state, payload)
     */
    channelLeft(state, payload) {
      //if channel is public then set join false
      if (payload.data.type == "public" || payload.data.type == "query") {
        payload.storeData[payload.index].joined = false;
        if (payload.data.type == "public") {
          payload.storeData[payload.index].messages = [];
        }
      }
      //remove that channel if private
      else {
        payload.storeData.splice(payload.index, 1);
      }
    },

    /**
     * This mutation will update channel Name
     * args:(state, payload)
     */
    channelNameUpdated(state, payload) {
      payload.storeData.name = payload.data.channel.name;
    },
    /**
     * This mutation will update channel description
     * args:(state, payload)
     */
    channelDescriptionUpdated(state, payload) {
      payload.storeData.description = payload.data.channel.description;
    },
    /**
     * This mutation will update channel creator
     * args:(state, payload)
     */
    channelCreatorUpdated(state, payload) {
      payload.storeData.creator_id = payload.data.channel.creator_id;
      if (payload.loginUserId == payload.data.channel.creator_id) {
        payload.storeData.all_users_restricted =
          payload.data.channel.all_users_restricted;
        payload.storeData.allowed_senders =
          payload.data.channel.allowed_senders;
        payload.storeData.is_read_only = false;
      }
    },
    /**
     * This mutation will archive channel
     * args:(state, payload)
     */
    channelArchived(state, payload) {
      if (payload.index != -1) {
        payload.storeData.splice(payload.index, 1);
      }
    },
    /**
     * This mutation will mute channel
     * args:(state, payload)
     */
    channelMute(state, payload) {
      payload.storeData.muted = payload.data.mute;
    },
    /**
     * This mutation will flag/unflag channel
     * args:(state, payload)
     */
    channelFlag(state, payload) {
      payload.storeData.flagged = payload.flagged;
    },
    /**
     * This mutation is responsible for mutate local store and archived public/private channel
     * args:(specific private/public channel object
     **/
    channelUnArchived(state, payload) {
      payload.archived = null;
    },
    /**
     * This mutation is responsible for mutate local store and and add new direct/group channel
     * args:(channels, newChannelData)
     **/
    addDirectChannelInCompany(state, payload) {
      payload.channels.push(payload.newChannelData);
    },
    /**
     * This mutation is responsible for mutate local store and and remove direct/group channel
     * args:(channels, newChannelData, channelIndex)
     **/
    removeDirectChannelFromCompany(state, payload) {
      payload.channels.splice(payload.channelIndex, 1);
    },
    changeIsReadOnly(state, payload) {
      debug.log("i am payload", payload);
      if (payload.allUsersRestricted != undefined) {
        payload.channelposition.all_users_restricted =
          payload.allUsersRestricted;
      }
      if (payload.isReadOnly != undefined) {
        payload.channelposition.is_read_only = payload.isReadOnly;
      }
    },
    allowedSenderAdded(state, payload) {
      payload.allowed_users.forEach((userId) => {
        if (!payload.channelposition.allowed_senders.includes(userId)) {
          payload.channelposition.allowed_senders.push(userId);
        }
      });
      payload.channelposition.all_users_restricted = payload.allUsersRestricted;
    },
    allowedSenderRemoved(state, payload) {
      let index;
      for (let x of payload.removed_sender) {
        index = payload.channelposition.allowed_senders.findIndex(
          (id) => id === x
        );
        if (index != -1) {
          payload.channelposition.allowed_senders.splice(index, 1);
        }
      }
      payload.channelposition.all_users_restricted = payload.allUsersRestricted;
    },
    addedAllowedSender(state, payload) {
      payload.channelposition.is_read_only = false;
    },
    removedAllowedSender(state, payload) {
      payload.channelposition.is_read_only = true;
    },
    cloudChannelUpdate(state, payload) {
      if ("invited" in payload.newData) {
        payload.channel.invited = payload.newData.invited;
      }
      if ("guests" in payload.newData) {
        payload.channel.guests = payload.newData.guests;
      }
      if ("connect_url" in payload.newData) {
        payload.channel.connect_url = payload.newData.connect_url;
      }
    },
  },
};

export default channelSocket;
