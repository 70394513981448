import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import debug from "@/console";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:companyId/:teamId/:channelId",
      name: "home",
      component: () =>
        import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "home" */
          "@/views/Home.vue"
        ),
      meta: {
        title: "Whistle It - Home",
        requireAuth: true,
      },
    },

    {
      path: "/firstLogin",
      name: "firstLogin",
      component: () =>
        import(
          /* webpackChunkName: "FirstLogin" */
          "@/views/FirstLogin.vue"
        ),
      meta: {
        title: "Whistle It - First Login",
      },
    },
    {
      path: "/zoom",
      name: "zoom",
      component: () =>
        import(
          /* webpackChunkName: fewfsfsfdsfsdfs"Zoom" */
          "@/views/Zoom.vue"
        ),
      meta: {
        title: "Whistle It - Zoom",
      },
    },
    {
      path: "/",
      name: "login",
      component: () =>
        import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "Login" */
          "@/views/Login.vue"
        ),
      meta: {
        title: "Whistle It - Login",
      },

      // This Route Guard will prevent users to go to the admin side manually
      beforeEnter: (to, from, next) => {
        // it is to check whether user has session token or not, if yes, then redirect him to home
        if (JSON.parse(localStorage.getItem("token"))) {
          setTimeout(() => {
            store.dispatch("changeHomeRoute");
          }, 10);
        } else {
          next();
        }
      },
    },
    {
      path: "/enteremail",
      name: "enteremail",
      component: () =>
        import(
          /* webpackChunkName: "Enter email" */
          "@/views/EnterEmail.vue"
        ),
      meta: {
        title: "Whistle It - Email OTP",
      },
    },

    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () =>
        import(
          /* webpackChunkName: "resetpassword" */
          "@/views/ResetPassword.vue"
        ),
      meta: {
        title: "Whistle It - Reset Password",
      },
    },
    {
      path: "/signup",
      name: "signupform",
      component: () =>
        import(
          /* webpackChunkName: "signup" */
          "@/views/SignupForm.vue"
        ),
      meta: {
        title: "Whistle It - Sign up",
      },
    },

    {
      path: "/linkexpire",
      name: "LinkExpire",
      component: () =>
        import(
          /* webpackChunkName: "linkexpire" */
          "@/views/LinkExpire.vue"
        ),
      meta: {
        title: "Whistle It - Expired Link",
      },
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: () =>
        import(
          /* webpackChunkName: "Maintenance" */
          "@/views/Maintenance.vue"
        ),
      meta: {
        title: "Whistle It - Page not found",
      },
    },

    {
      path: "*",
      name: "Error404",
      component: () =>
        import(
          /* webpackChunkName: "error" */
          "@/views/Error404.vue"
        ),
      meta: {
        title: "Whistle It - Page not found",
      },
    },
    {
      path: "/subscriptionExpired",
      name: "SubscriptionExpired",
      component: () =>
        import(
          /* webpackChunkName: "subscription expired" */
          "@/views/SubscriptionExpired.vue"
        ),
      meta: {
        title: "Whistle It - Access Denied",
        requireAuth: true,
      },
    },
    {
      path: "/2fa_qr/:QRData",
      name: "2FA_QR",
      component: () =>
        import(
          /* webpackChunkName: "2fa" */
          "@/views/qr.vue"
        ),
      meta: {
        title: "Whistle It - 2FA_QR",
      },
    },

    {
      path: "/redirect",
      name: "Loading",
      component: () =>
        import(
          /* webpackChunkName: "redirect" */
          "@/views/Loader.vue"
        ),
      meta: {
        title: "Whistle It - 2FA_QR",
      },
    },

    {
      path: "/thankyou",
      name: "thankyou",
      component: () =>
        import(
          /* webpackChunkName: "thankyou" */
          "@/views/ThankYou.vue"
        ),
      meta: {
        title: "Whistle It - Thank You",
      },
    },
    {
      path: "/2fa_email",
      name: "2FA_Email",
      component: () =>
        import(
          /* webpackChunkName: "2fa email" */
          "@/views/2FA_Email.vue"
        ),
      meta: {
        title: "Whistle It - 2FA_Email Verification",
      },
    },

    {
      path: "/2fa_key/:token",
      name: "2FA_Key",
      component: () =>
        import(
          /* webpackChunkName: "2fa key" */
          "@/views/2FA_Key.vue"
        ),
      meta: {
        title: "Whistle It - 2FA_Key",
      },
    },

    {
      path: "/otp/:token",
      name: "Otp",
      component: () =>
        import(
          /* webpackChunkName: "otp" */
          "@/views/otp.vue"
        ),
      meta: {
        title: "Whistle It - OTP",
      },
    },

    //route to Link join team

    {
      path: "/teamlink",
      name: "LinkForTeamJoining",
      component: () =>
        import(
          /* webpackChunkName: "team join" */
          "@/views/LinkForTeamJoining.vue"
        ),
      meta: {
        title: "Whistle It - Join Team",
      },
    },
    {
      path: "/invitationaccepted",
      name: "InvitationAccepted",
      component: () =>
        import(
          /* webpackChunkName: "invitation" */
          "@/views/InvitationAccepted.vue"
        ),
      meta: {
        title: "Whistle It - Getting Started",
      },
    },

    {
      path: "/admindashboard",
      name: "admindashboard",
      component: () =>
        import(
          /* webpackChunkName: "admin dashboard" */
          "@/views/AdminDashboardView.vue"
        ),
      meta: {
        title: "Whistle It - Admin",
        requireAuth: true,
      },

      children: [
        {
          path: "/users",
          name: "users",
          component: () =>
            import(
              /* webpackChunkName: "users" */
              "@/components/AdminDashboard/UsersPanel/UsersPannel.vue"
            ),
          meta: {
            title: "Whistle It - Users",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.company_member
                  .length > 0
              ) {
                next();
              } else {
                next({
                  name: "teams",
                });
              }
            }, 10);
          },
        },
        {
          path: "/teams",
          name: "teams",
          component: () =>
            import(
              /* webpackChunkName: "teams" */
              "@/components/AdminDashboard/TeamPanel/TeamsPannel.vue"
            ),
          meta: {
            title: "Whistle It - Teams",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                (store.getters.getSelectedCompany.permissions.team &&
                  store.getters.getSelectedCompany.permissions.team.length > 0)
              ) {
                next();
              } else {
                next({
                  name: "channels",
                });
              }
            }, 10);
          },
        },
        {
          path: "/department",
          name: "department",
          component: () =>
            import(
              /* webpackChunkName: "department" */
              "@/components/AdminDashboard/DepartmentModal/DepartmentPanel.vue"
            ),
          meta: {
            title: "Whistle It - Departments",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                (store.getters.getSelectedCompany.permissions.company &&
                  store.getters.getSelectedCompany.permissions.company.length >
                    0)
              ) {
                next();
              } else {
                next({
                  name: "department",
                });
              }
            }, 10);
          },
        },
        {
          path: "/channels",
          name: "channels",
          component: () =>
            import(
              /* webpackChunkName: "channels" */
              "@/components/AdminDashboard/ArchivedChannelsPannel.vue"
            ),
          meta: {
            title: "Whistle It - Channels",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.channel.length > 0
              ) {
                next();
              } else {
                next({
                  name: "attachments",
                });
              }
            }, 10);
          },
        },
        // Admin Settings
        {
          path: "/rolepannel",
          name: "rolepannel",
          component: () =>
            import(
              /* webpackChunkName: "rolespannel" */
              "@/components/AdminDashboard/RolePannel.vue"
            ),
          meta: {
            title: "Whistle It - Roles Panel",
          },
        },
        {
          path: "/assignrole",
          name: "assignrole",
          component: () =>
            import(
              /* webpackChunkName: "assign role" */
              "@/components/AdminDashboard/AssignRole.vue"
            ),
          meta: {
            title: "Whistle It - Role Assignment",
          },
        },
        {
          path: "/attachments",
          name: "attachments",
          component: () =>
            import(
              /* webpackChunkName: "attachments" */
              "@/components/AdminDashboard/AdminSettings/Attachments.vue"
            ),
          meta: {
            title: "Whistle It - Attachments",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.attachments
                  .length > 0
              ) {
                next();
              } else {
                next({
                  name: "ipsecurity",
                });
              }
            }, 10);
          },
        },
        {
          path: "/generalandsecurity",
          name: "generalandsecurity",
          component: () =>
            import(
              /* webpackChunkName: "admin settings" */
              "@/components/AdminDashboard/AdminSettings/AdminGeneralSettings.vue"
            ),
          meta: {
            title: "Whistle It - Account Settings",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.company.length > 0
              ) {
                next();
              } else {
                store.dispatch("changeHomeRoute");
              }
            }, 10);
          },
        },
        {
          path: "/ipsecurity",
          name: "ipsecurity",
          component: () =>
            import(
              /* webpackChunkName: "ip security" */
              "@/components/AdminDashboard/AdminSettings/IpSecurityPannel.vue"
            ),
          meta: {
            title: "Whistle It - IP Security",
          },

          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.ip.length > 0
              ) {
                next();
              } else {
                next({
                  name: "payment",
                });
              }
            }, 10);
          },
        },
        {
          path: "/payment",
          name: "payment",
          component: () =>
            import(
              /* webpackChunkName: "payment" */
              "@/components/AdminDashboard/AdminSettings/Payment.vue"
            ),
          meta: {
            title: "Whistle It - Payment",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions.payment.length > 0
              ) {
                next();
              } else {
                next({
                  name: "generalandsecurity",
                });
              }
            }, 10);
          },
        },
        {
          path: "/apps",
          name: "apps",
          component: () =>
            import(
              /* webpackChunkName: "payment" */
              "@/components/AdminDashboard/apps/AppsList.vue"
            ),
          meta: {
            title: "Whistle It - Apps",
          },
          beforeEnter: (to, from, next) => {
            setTimeout(() => {
              if (
                store.getters.getSelectedCompany.role_name == "admin" ||
                store.getters.getSelectedCompany.permissions?.apps?.length > 0
              ) {
                next();
              } else {
                next({
                  name: "generalandsecurity",
                });
              }
            }, 10);
          },
        },
      ],
    },

    // Mobile components
    {
      path: "/intro",
      name: "intro",
      component: () =>
        import(
          /* webpackChunkName: "mobile intro" */
          "@/components/MobileComponents/Intro_mobile.vue"
        ),
      meta: {
        title: "Whistle It - Intro",
      },
    },
    {
      path: "/loginloader",
      name: "Login Loader",
      component: () =>
        import(
          /* webpackChunkName: "SuperAdminView" */
          "@/views/SuperAdminLogin.vue"
        ),
      meta: {
        title: "Whistle It - Super Admin Login",
      },
    },
    {
      path: "/quickchat",
      name: "quickReply",
      component: () =>
        import(
           /* webpackPrefetch: true */
          /* webpackChunkName: "quickreply" */
          "@/views/QuickReply.vue"
        ),
      meta: {
        title: "Whistle It - Quick-Reply",
        requireAuth: true,
      },
    },
  ],
});

// Route Guards
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!JSON.parse(localStorage.getItem("token"))) {
      next({
        name: "login",
      });
    } else {
      await store.restored;

      next();
    }
  } else {
    await store.restored;

    next();
  }
});

export default router;
