/**
 * System: Whistle It
 * Developer: Nabil Ahmad & Ahmad Hanan
 * Organization: Programmers Force
 * Purpose: This file is for listening and changing updated data in admin store.
 */
import localForage from "localforage";

import debug from "@/console";
var userSocket = {
  getters: {},

  actions: {
    /**
     * This action listen for socket whenever user is going to make connection with node server
     *
     * @return {void}
     */
    socket_userAuthentication(context) {
      context.dispatch("authentication");
    },

    /**
     * This action listen for socket whenever user is going to reconnect with node server
     *
     * @return {void}
     */
    socket_reconnect(context) {
      context.dispatch("authentication");
    },

    /**
     * This action listen for socket and get data of user who currently online
     *
     * @param {Object} data online user data
     * @return {void}
     */
    socket_userOnline({ commit, rootGetters }, data) {
      //call mutation to update all the user status to online
      commit("SET_ONLINE_USER", {
        data,
        direct_users: rootGetters.getDirectChannels,
        company_users: rootGetters.getSelectedCompany.users,
        selected_company: rootGetters.getSelectedCompany,
        company: rootGetters.getLoginUser.companies.find(
          (company) => company._id == data.company_id
        ),
      });
      //call mutation to set user status accordingly
      commit("SET_USER_STATUS", {
        data: data,
        users: rootGetters.getSelectedCompany.users,
      });
    },

    /**
     * This action listen for socket and get data of all the online users
     *
     * @param {Object} data online users data
     * @return {void}
     */
    socket_usersOnline({ commit, rootGetters }, data) {
      commit("SET_ONLINE_USERS", {
        data,
        company_users: rootGetters.getDirectChannels,
        users: rootGetters.getSelectedCompany.users,
        current_company: rootGetters.getSelectedCompany,
        companies: rootGetters.getLoginUser.companies,
      });
      //update user status
      commit("SET_USERS_STATUS", {
        data: data,
        users: rootGetters.getSelectedCompany.users,
      });
    },

    /**
     * This action listen for socket whenever user profile picture is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userProfilePictureUpdate(context, data) {
      //call mutation to update user profile pic
      context.commit("userProfilePictureUpdate", {
        storeData: context.rootState.storeData,
        data: data,
      });
    },

    /**
     * This action listen for socket whenever user is permission is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_permissionsUpdated(context, data) {
      //loop through permissions array
      data.permissions.forEach((permission) => {
        //find compay
        let comp = context.rootGetters.getCompanies.find(
          (company) => company._id == permission.company_id
        );
        //if company found
        if (comp) {
          //call mutation to update user permissions
          context.commit("UPDATE_PERMISSIONS", {
            company: comp,
            permissions: permission.permission,
            old_permission: context.rootGetters.getPermissions,
          });
          //call mutation to new permission in permission array
          context.commit("SET_LINK", {
            permissions: permission.permission,
          });
        }
      });
    },

    /**
     * This action emit to node server for user authentication
     *
     * @param {Object} data user data
     * @return {void}
     */
    authenticateSocket(context, data) {
      data.company_ids = context.rootGetters.getCompaniesIds;
      this._vm.$socket.client.emit("authenticate", data);
    },

    /**
     * This action take data from local storage and make emit to node server for authentication
     *
     * @return {void}
     */
    authentication(context) {
      localForage
        .getItem("APP_DATA")
        .then((value) => {
          if (value.storeData !== null) {
            let token = JSON.parse(localStorage.getItem("token"));

            if (token != null) {
              let selected_company = context.rootGetters.getSelectedCompany._id;
              this._vm.$socket.client.emit("authenticate", {
                token: token,
                selected_company: selected_company,
                status: context.rootGetters.loggedInUserDetails.status,
                company_ids: context.rootGetters.getCompaniesIds,
                version: localStorage.getItem("App_Version"),
              });
            }
          }
        })
        .catch((err) => {
          debug.log(err);
        });
    },

    /**
     * This action emit to node server for socket disconnection
     *
     * @return {void}
     */
    disconnectSocket() {
      localForage
        .getItem("APP_DATA")
        .then((value) => {
          if (value.storeData !== null) {
            this._vm.$socket.client.emit("leaveRooms", value.storeData);
          }
        })
        .catch((err) => {
          debug.log(err);
        });
    },

    /**
     * This action listen for socket whenever user is archived from company
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_unarchivedCompany(context, data) {
      //find company
      let companyIndex = context.rootState.storeData.companies.findIndex(
        (company) => company._id == data.companies[0]._id
      );
      //call mutation to archive specific user
      context.commit("unarchivedSpecificUser", {
        companies: context.rootState.storeData.companies,
        companyIndex,
        newCompanyData: data.companies[0],
      });
    },

    /**
     * This action listen for socket for all other users whenever user is archived from company
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userUnarchivedFromCompany(context, data) {
      //find company
      let company = context.rootState.storeData.companies.find(
        (company) => company._id == data.company_id
      );
      context.commit("userUnArchived", {
        company,
        data,
      });
    },

    /**
     * This action listen for socket for all other users whenever user status is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userStatusUpdate(context, data) {
      //find user all companies
      let userCompanies = context.rootGetters.getUserCompanies(data.user_id);
      //find logged in user
      let loggedInUser = context.rootGetters.loggedInUserDetails;
      //call mutation to update user status
      context.commit("userStatusUpdate", {
        userCompanies,
        loggedInUser,
        data,
      });
    },

    /**
     * This action listen for socket whenever user status is set dnd
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userDoNotDisturb(context, data) {
      //find user companies
      let userCompanies = context.rootGetters.getUserCompanies(data.user_id);
      //find logged in user
      let loggedInUser = context.rootGetters.loggedInUserDetails;
      //call mutation to update status to dnd
      context.commit("userDoNotDisturb", {
        userCompanies,
        loggedInUser,
        data,
      });
    },

    /**
     * This action listen for socket whenever user custom status is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userSettingsUpdated(context, data) {
      //find all companies of user
      let userCompanies = context.rootGetters.getUserCompanies(data.user_id);
      //find logged in user
      let loggedInUser = context.rootState.storeData;

      context.commit("userSettingsUpdated", {
        userCompanies,
        loggedInUser,
        data,
      });
    },

    /**
     * This action listen for socket whenever user notification settings updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userNotificationUpdate(context, data) {
      try {
        //find logged in user
        let loggedInUser = context.rootGetters.getUserFromCompanyUsers(
          data.company_id,
          data.user_id
        );
        //call mutation to update user notification settings
        context.commit("userPushNotification", {
          loggedInUser,
          data,
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * This action listen for socket whenever user hide email
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userHideEmail(context, data) {
      //find user companies
      let userCompanies = context.rootGetters.getUserCompanies(data.user_id);
      //find logged in user
      let loggedInUser = context.rootGetters.loggedInUserDetails;
      //call mutation to hide email
      context.commit("userHideEmail", {
        userCompanies,
        loggedInUser,
        data,
      });
    },

    /**
     * This action listen for socket whenever user access is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userAllowAccessModified(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company finds
      if (company) {
        //call mutation to update user access
        context.commit("updateUserAllowAccess", {
          companyUsers: company.users,
          data,
        });
      }
    },

    /**
     * This action listen for socket whenever user designation is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userDesignationUpdated(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company finds
      if (company) {
        //call mutation to update user designation
        context.commit("userDesignationUpdate", {
          company,
          data,
        });
      }
    },

    /**
     * This action listen for socket whenever user job description is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userJobDescriptionUpdated(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company finds
      if (company) {
        //call mutation to update user job description
        context.commit("UPDATE_JOB_DESCRIPTION", {
          company,
          data,
        });
      }
    },

    /**
     * This action listen for socket whenever user alias is updated
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_userAliasUpdated(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company finds
      if (company) {
        //call mutation to update user alias
        context.commit("UPDATE_USER_ALIAS", {
          company,
          data,
        });
      }
    },

    /**
     * This action listen for socket whenever new user is added in guest channel
     *
     * @param {Object} data user data
     * @return {void}
     */
    async socket_guestUserAdded(context, data) {
      //find company index
      let companyIndex = await context.rootState.storeData.companies.findIndex(
        (company) => company._id == data.company_id
      );
      //if company find
      if (companyIndex > -1) {
        //call mutation to add user in guest channel
        context.commit("addGuestUser", {
          storeData: context.rootState.storeData,
          companyIndex,
          data,
        });
      }
    },

    /**
     * This action listen for socket whenever user is removed from guest channel
     *
     * @param {Object} data user data
     * @return {void}
     */
    socket_guestUserRemoved(context, data) {
      //find company
      let company = context.rootGetters.getCompanyById(data.company_id);
      //if company finds
      if (company) {
        //call mutation to remove guest user
        context.commit("removeGuestUser", { company, data });
      }
    },
  },

  mutations: {
    /**
     * This mutation set/update user status in store
     *
     * @param {Object} payload user data
     * @return {void}
     */
    SET_USERS_STATUS: (state, payload) => {
      //loop through all users
      payload.users.forEach((companyUser) => {
        //set status to 0
        if (companyUser.status != "1") companyUser.status = "0";
      });
      //loop through all users
      payload.data.users.forEach((user) => {
        payload.users.forEach((companyUser) => {
          if (companyUser._id == user.user_id) {
            companyUser.status = user.status;
            return;
          }
        });
      });
    },

    /**
     * This mutation set/update specific user status in store
     *
     * @param {Object} payload user data
     * @return {void}
     */
    SET_USER_STATUS: (state, payload) => {
      //loop through all users
      payload.users.forEach((companyUser) => {
        if (companyUser._id == payload.data.user_id) {
          //set status
          companyUser.status = payload.data.status;
        }
      });
    },

    /**
     * This mutation set/update user status in store
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userStatusUpdate: (state, payload) => {
      payload.userCompanies.forEach((company) =>
        company.users.forEach((user) => {
          if (user._id == payload.data.user_id) {
            if (payload.loggedInUser._id == payload.data.user_id) {
              payload.loggedInUser.status = payload.data.status;
            }
            user.status = payload.data.status;
          }
        })
      );
    },

    /**
     * This mutation set/update user dnd status in store
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userDoNotDisturb: (state, payload) => {
      //loop through user companies
      payload.userCompanies.forEach((company) =>
        //loop through all user of companies
        company.users.forEach((user) => {
          if (user._id == payload.data.user_id) {
            if (payload.loggedInUser._id == payload.data.user_id) {
              // set status
              payload.loggedInUser.do_not_disturb = payload.data.do_not_disturb;
            }
            user.do_not_disturb = payload.data.do_not_disturb;
            return;
          }
        })
      );
    },

    /**
     * This mutation set/update user custom status in store
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userSettingsUpdated: (state, payload) => {
      //loop through all user companies
      payload.userCompanies.forEach((company) =>
        //loop through all users of each company
        company.users.forEach((user) => {
          //if user exist
          if (user._id == payload.data.user_id) {
            //if user is logged in user
            if (payload.loggedInUser._id == payload.data.user_id) {
              //if name exist in payload
              if (payload.data.name)
                // set name
                payload.loggedInUser.name = payload.data.name;
              //if custom_status key exist
              if ("custom_status" in payload.data)
                // set custom status
                payload.loggedInUser.custom_status = payload.data.custom_status;
            }
            if (payload.data.name) user.name = payload.data.name;
            if ("custom_status" in payload.data)
              user.custom_status = payload.data.custom_status;
            return;
          }
        })
      );
    },

    /**
     * This mutation set user push notification data
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userPushNotification: (state, payload) => {
      try {
        //if logged in user
        if (
          payload.loggedInUser &&
          payload.loggedInUser._id == payload.data.user_id
        ) {
          //set in app notification
          payload.loggedInUser.in_app_notification =
            payload.data.in_app_notification;
          //set notification sound
          payload.loggedInUser.notification_sound =
            payload.data.notification_sound;
          //set email notification
          payload.loggedInUser.email_notification =
            payload.data.email_notification;
          //set pusg notification
          payload.loggedInUser.push_notification =
            payload.data.push_notification;
        }
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * This mutation update user email settings
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userHideEmail: (state, payload) => {
      //loop through user companies
      payload.userCompanies.forEach(
        (company) =>
          //loop through all user of each company and set email
          (company.users.find((user) => {
            return user._id == payload.data.user_id;
          }).email = payload.data.email)
      );
    },

    /**
     * This mutation set user archive to null and add user in team and channels
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userUnArchived: (state, payload) => {
      //set archive null in company users
      payload.company.users.find(
        (user) => user._id == payload.data.user_id
      ).archived = null;
      //add in teams
      payload.company.teams.forEach((team) => {
        payload.data.teams.forEach((dataTeamId) => {
          if (team._id == dataTeamId) {
            team.users.push(payload.data.user_id);
          }
        });
      });
      //add in general channels of teams
      payload.data.teams.forEach((dataTeamId) => {
        payload.company.channels.forEach((channel) => {
          if (channel.team_id == dataTeamId && channel.default) {
            channel.users.push(payload.data.user_id);
          }
        });
      });
      //set activate status to true in direct channel which was with archived user
      payload.company.direct.forEach((directChannel) => {
        if (directChannel.users.includes(payload.data.user_id)) {
          directChannel.activate = true;
        }
      });
    },

    /**
     * This mutation set specific user archive to null and add user in team and channels
     *
     * @param {Object} payload user data
     * @return {void}
     */
    unarchivedSpecificUser: (state, payload) => {
      //if company exist
      if (payload.companyIndex > -1) {
        //replace company object
        Object.assign(
          payload.companies[payload.companyIndex],
          payload.newCompanyData
        );
      }
      //if company not exist for user
      else {
        //push company
        payload.companies.push(payload.newCompanyData);
      }
    },

    /**
     * This mutation set online users
     *
     * @param {Object} payload user data
     * @return {void}
     */
    setOnlineUsers: (state, payload) => {
      //loop through direct channels
      payload.directChannels.filter((user) => {
        for (const item of payload.data.users) {
          if (user.creator_id == item.user_id || user.user_id == item.user_id) {
            user.online = true;
          }
        }
      });
    },

    /**
     * This mutation update user profile pic
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userProfilePictureUpdate(state, payload) {
      //find company
      let company = payload.storeData.companies.find(
        (company) => company._id == payload.data.company_id
      );
      //if company exist
      if (company) {
        //if user exist
        if (payload.storeData._id == payload.data.user_id) {
          //set new profile pic
          company.profile_picture = payload.data.profile_picture;
          company.original_profile_picture =
            payload.data.original_profile_picture;
        }
        //find user from company
        let userInfo = company.users.find(
          (user) => user._id == payload.data.user_id
        );
        //if user exit
        if (userInfo) {
          //set user new profile pic
          userInfo.profile_picture = payload.data.profile_picture;
          userInfo.original_profile_picture =
            payload.data.original_profile_picture;
          //find direct channel
          let directChannel = company.direct.find(
            (channel) =>
              channel.users.length == 1 &&
              channel.users.includes(payload.data.user_id)
          );
          //if channel exist
          if (directChannel) {
            //set profile
            directChannel.profile_picture = payload.data.profile_picture;
          } else {
            directChannel = company.direct.find(
              (channel) =>
                channel.users.length == 2 &&
                channel.users.includes(payload.data.user_id)
            );
            if (directChannel) {
              directChannel.profile_picture = payload.data.profile_picture;
            }
          }
        }
      }
    },

    /**
     * This mutation set/update user online user
     *
     * @param {Object} payload user data
     * @return {void}
     */
    SET_ONLINE_USER: (state, payload) => {
      // Update direct channels status to online

      // To set user online if he gets online in the same company
      if (payload.data.company_id == payload.selected_company._id) {
        //loop through direct users
        payload.direct_users.filter((user) => {
          if (
            user.user_id == payload.data.user_id ||
            user.creator_id == payload.data.user_id
          ) {
            user.online = true;
          }
        });
        //for company user online
        payload.company.users.forEach(function(part, index) {
          if (this[index]._id == payload.data.user_id) {
            this[index].online = true;
          }
        }, payload.company.users);
      }
      // To set user online if he gets online in other company
      payload.company.users.find((user) => {
        if (user._id == payload.data.user_id) {
          user.online = true;
        }
      });
    },
    /**
     * This mutation set/update all user user online status
     *
     * @param {Object} payload user data
     * @return {void}
     */
    SET_ONLINE_USERS: (state, payload) => {
      //To set users online, present in current team
      for (let user of payload.company_users) {
        for (let i = 0; i < payload.data.users.length; i++) {
          if (
            payload.data.users[i].user_id == user.creator_id ||
            payload.data.users[i].user_id == user.user_id
          ) {
            user.online = true;
          }
        }
      }

      // To get online, all users of the compnay
      for (const currentUser of payload.users) {
        for (let i = 0; i < payload.data.users.length; i++) {
          if (currentUser._id == payload.data.users[i].user_id) {
            currentUser.online = true;
          }
        }
      }

      for (let i = 0; i < payload.data.users.length; i++) {
        payload.companies.forEach((company) => {
          let userFound = company.users.find(
            (user) => user._id == payload.data.users[i].user_id
          );

          if (userFound) {
            userFound.online = true;
            userFound.status = payload.data.users[i].status;
          }
        });
      }
    },

    /**
     * This mutation update user permissions
     *
     * @param {Object} payload user data
     * @return {void}
     */
    UPDATE_PERMISSIONS: (state, payload) => {
      payload.company.permissions = payload.permissions;
    },

    /**
     * This mutation update user global access
     *
     * @param {Object} payload user data
     * @return {void}
     */
    updateUserAllowAccess: (state, payload) => {
      //find specific user
      let user = payload.companyUsers.find(
        (user) => user._id == payload.data.user_id
      );
      if (user) {
        //update it's allow access
        user.allow_access = payload.data.allow_access;
      }
    },

    /**
     * This mutation update user designation
     *
     * @param {Object} payload user data
     * @return {void}
     */
    userDesignationUpdate: (state, payload) => {
      //find user
      let findUser = payload.company.users.find(
        (user) => user._id == payload.data.user_id
      );
      //if user exist
      if (findUser) {
        if (
          payload.data.designation == "null" ||
          payload.data.designation == null
        ) {
          findUser.designation = "";
        } else if (payload.data.designation) {
          findUser.designation = payload.data.designation;
        }
      }
    },

    /**
     * This mutation update user job description
     *
     * @param {Object} payload user data
     * @return {void}
     */
    UPDATE_JOB_DESCRIPTION: (state, payload) => {
      //find user
      let findUser = payload.company.users.find(
        (user) => user._id == payload.data.user_id
      );
      //if user exist
      if (findUser) {
        findUser.job_description = payload.data.job_description;
      }
    },

    /**
     * This mutation update user alias
     *
     * @param {Object} payload user data
     * @return {void}
     */
    UPDATE_USER_ALIAS: (state, payload) => {
      //find user
      let findUser = payload.company.users.find(
        (user) => user._id == payload.data.user_id
      );
      //if user exist
      if (findUser) {
        findUser.alias = payload.data.alias;
      }
    },

    /**
     * This mutation add user in company guest users array
     *
     * @param {Object} payload user data
     * @return {void}
     */
    addGuestUser: (state, payload) => {
      payload.storeData.companies[payload.companyIndex].guest_users.splice(
        payload.storeData.companies[payload.companyIndex].guest_users.length,
        0,
        payload.data
      );
    },
    /**
     * This mutation remove user from company guest users array
     *
     * @param {Object} payload user data
     * @return {void}
     */
    removeGuestUser: (state, payload) => {
      payload.company.guest_users = payload.company.guest_users.filter(
        (guestUser) => guestUser._id != payload.data._id
      );
    },
  },
};

export default userSocket;
