<!-- 
System: Whistle It
Developer: Azwar Khan And Nouman Saifi
Organization: Whistle It
Purpose: This file is responsible for display audio on header. 
 -->
<template>
  <div
    class=" rounded-lg audio-tray pt-0 pb-3"
    :ref="dragHandleId"
    v-draggable="draggableValue"
  >
    <div
      class="row header-class rounded-lg rounded-b-0 d-flex justify-space-between mt-0"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="white"
            @click="onClickGotoChannel"
            @touchstart="onClickGotoChannel"
          >
            <v-icon>mdi-open-in-new</v-icon></v-btn
          >
        </template>
        <span>Go Back</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            color="white"
            @click="audioClose"
            @touchstart="audioClose"
          >
            <v-icon>mdi-close-circle-outline</v-icon></v-btn
          >
        </template>
        <span>Close</span>
      </v-tooltip>
    </div>
    <div class="row justify-center ">
      <p>{{ this.Urlpath.audioUserName.name }}</p>
    </div>
    <div class="d-flex">
      <audio
        ref="headerAudio"
        controls
        @ended="onEnded()"
        autoplay
        @pause="pasueLisner()"
        @play="playLisner()"
        class="px-0 a-audio mb-1"
        :class="{
          'medium-display': $vuetify.breakpoint.mdAndUp,
          'small-display': $vuetify.breakpoint.smAndDown,
        }"
      >
        <source :src="Urlpath.url" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>

<script>
import { Draggable } from "draggable-vue-directive";

export default {
  name: "AudioPlayerInHeader",
  props: ["Urlpath"],
  directives: {
    Draggable,
  },
  data() {
    return {
      pauseCheckValue: false,
      closeButtonCheck: 0,
      dragHandleId: "drag-handle-id",
      draggableValue: {
        handle: undefined,
        //on mobile and tablet view stop dragging
        //  stopDragging: this.$vuetify.breakpoint.smAndDown,
        initialPosition: false,
        resetInitialPos: true,
        mediaStreamObj: undefined,
        showMaxLimitMsg: false,
      },
    };
  },

  mounted() {
    this.draggableValue.handle = this.$refs[this.dragHandleId];
    /* current time, url and speed comes through props object */
    this.$refs.headerAudio.currentTime = this.Urlpath.urlTime;
    this.$refs.headerAudio.playbackRate = this.Urlpath.audioSpeed;
  },

  methods: {
    onEnded() {
      /* This function will trigger automatically on the audio ending */
      this.$store.commit("setCurrentLiveAudioState", {});
    },
    /* on pause this function will trigge */
    pasueLisner() {
      this.pauseCheckValue = true;
    },
    /* on play lisner this function will trigge */
    playLisner() {
      this.pauseCheckValue = false;
    },
    /* Click to close the Playing Audio */
    audioClose() {
      this.closeButtonCheck = 1;
      this.$store.commit("setCurrentLiveAudioState", {});
      this.$store.commit("setCurrentRemainingAudio", {});
    },
    /* Click to go to the channel the audio is playing on. */
    onClickGotoChannel() {
      let channelsId = this.Urlpath.channelsIdDetail;
      this.$router.push({
        name: "home",
        params: {
          companyId: channelsId.selectedCompanyId,
          teamId: channelsId.selectedTeamId,
          channelId: channelsId.selectedChannel._id,
        },
      });
    },
  },

  beforeDestroy() {
    if (this.closeButtonCheck == 0) {
      let remainingAudioTime = this.$refs.headerAudio.currentTime;
      let remainingAudioURL = this.$refs.headerAudio.currentSrc;
      this.$store.commit("setCurrentRemainingAudio", {
        pauseCheck: this.pauseCheckValue,
        url: remainingAudioURL,
        urlTime: remainingAudioTime,
        // channelsIdDetail: this.channelsId,
      });
      this.$store.commit("setCurrentLiveAudioState", {});
    }
  },
};
</script>

<style scoped>
audio::-webkit-media-controls-panel {
  background-color: white;
}

.a-audio {
  height: 30px !important;
  border: 1px solid #dee2ed !important;
  border-radius: 20px;
}

.medium-display {
  width: 300px;
}

.small-display {
  width: 290px;
  place-content: center;
  align-content: center;
  padding-bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
}

.color-for-audio {
  background-color: #2d2b51;
  color: white;
}

.audio-tray {
  position: fixed !important;
  right: 0 !important;
  top: 60px;
  z-index: 999 !important;
  width: 310px !important;
  padding-left: 12px;
  padding-right: 6px;
  background-color: white;
  cursor: move;
}

.header-class {
  width: 310px !important;
  cursor: move;
  color: #34325f;
  background-color: #34325f;
  padding: 1px;
}
</style>
