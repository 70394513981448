import messageAPI from "../../api/messageAPI";
const state = {
  isOpenGlobalSearchDrawer: false,
  isOpenShortcutDialog: false,
  isUnreadMessage: false,
};
const getters = {
  /**
   * this getter will return if there is gloabl search drawer is opened
   */
  getIsOpenGlobalSearchDrawer: (state) => state.isOpenGlobalSearchDrawer,

  /**
  * this getter will return if there is unread message drawer is opened
  */
  getIsUnreadMessage: (state) => state.isUnreadMessage,
  /**
   * this getter will return if there is shortcut command dialog is opened or not
   */
  getIsOpenShortcutDialog: (state) => state.isOpenShortcutDialog,
};
const actions = {
  /**
   * This action is responsible for getting global search data
   * args:(context)
   */
  readGlobalSearchData(context, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };

      messageAPI.globalSearchAPI(payload, done);
    });
  },
  /**
   * This action is responsible for getting new files data in global search
   * args:(context)
   */
  searchFiles(context, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      messageAPI.searchFileApi(payload, done);
    });
  },

  getPublicShareableUrl(context, payload) {
    payload.company_id = context.rootGetters.getSelectedCompany._id;

    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      messageAPI.getPublicShareableUrl(payload, done);
    });
  },
};

const mutations = {
  //update global search drawer state i.e. opened or not
  UPDATE_GLOBAL_SEARCH_DRAWER: (state, payload) => {
    state.isOpenGlobalSearchDrawer = payload;
  },
  UNREAD_MESSAGE_DRAWER: (state, payload) => {
    state.isUnreadMessage = payload;
  },
  //update shortcut dialog state i.e. opened or not
  UPDATE_SHORTCUT_DIALOG: (state, payload) => {
    state.isOpenShortcutDialog = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
