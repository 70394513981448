import companyAPI from "@/api/companyAPI.js";
import debug from "@/console";
import companyStore from "./AdminStore/company";
import teamStore from "./AdminStore/teamStore";
import userStore from "./AdminStore/userStore";
import channelStore from "./AdminStore/channelStore";
import departmentStore from "./AdminStore/departmentStore";
import customAppsStore from "./AdminStore/customAppsStore";

const state = {
  invitationLink: null,
  links: [
    {
      icon: require("@/assets/AdminIcons/users icon.svg"),
      text: "Users",
      route: "/users",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/Teams icon.svg"),
      text: "Teams",
      route: "/teams",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/department.svg"),
      text: "Department",
      route: "/department",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/Channel icon.svg"),
      text: "Channel",
      route: "/channels",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/IP Settings icon.svg"),
      text: "IP Settings",
      route: "/ipsecurity",
      permission: false,
    },
    // This section is commented temporarily.We don't have to implement this feature in this version.
    {
      icon: "mdi-key",
      text: "Roles",
      route: "/rolepannel",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/attachments icon.svg"),
      text: "Attachments",
      route: "/attachments",
      permission: false,
    },
    {
      icon: require("@/assets/AdminIcons/payment-icon.svg"),
      text: "Payment",
      route: "/payment",
      permission: false,
    },
  ],
  mylinks: [],
};
const getters = {
  getLinks: (state) => state.mylinks,
  //get Selected Company Roles
  getRoles: (getters, rootGetters) => {
    // get company roles from root getter of selected company
    return rootGetters.getSelectedCompany.company_roles;
  },
  //get selected company invitatio link
  getInvitationLink: (state) => {
    //get invitation link from local state
    debug.log("invitationLof", state);
    return state.invitationLink;
  },
  //Get Team members length
  getTeamMembersLength: (state, getters, rootState, rootGetters) =>
    rootGetters.getDirectChannels.length + 1,
  //LoggedIn User Permission in Selected Company
  getSelectedCompanyPermission: (getters, rootGetters) => {
    return rootGetters.getSelectedCompany.permission;
  },
};
const mutations = {
  //set Role of User In Company
  setRole: (state, payload) => {
    debug.log(payload.roles);
    payload.roles.push(payload.obj);
  },

  roleAssignee: (state, payload) => {
    payload.role.assignee.push(payload.name);
  },
  ASSIGN_ROLE: (state, payload) => {
    debug.log(payload.obj.userId);
    payload.users.filter((item) => {
      item._id == payload.obj.userId;
    }).role_name = payload.obj.roleName;
  },

  SET_LINK: (state, payload) => {
    state.mylinks = [];
    if (
      payload &&
      payload.permissions &&
      Object.keys(payload.permissions).length > 0
    ) {
      if (payload.permissions.company_member.length > 0) {
        state.mylinks.push(state.links[0]);
      }
      if (payload.permissions.team.length > 0) {
        state.mylinks.push(state.links.find((link) => link.text == "Teams"));
      }
      if (
        payload.permissions.company &&
        payload.permissions.company.length > 0
      ) {
        state.mylinks.push(
          state.links.find((link) => link.text == "Department")
        );
      }
      if (payload.permissions.channel.length > 0) {
        state.mylinks.push(state.links.find((link) => link.text == "Channel"));
      }
      if (payload.permissions.attachments.length > 0) {
        state.mylinks.push(
          state.links.find((link) => link.text == "Attachments")
        );
      }
      if (payload.permissions.ip.length > 0) {
        state.mylinks.push(
          state.links.find((link) => link.text == "IP Settings")
        );
      }
      if (payload.permissions.payment.length > 0) {
        state.mylinks.push(state.links.find((link) => link.text == "Payment"));
      }
    }
  },
};
const actions = {
    //Create New Role In Compoany
    // This Action is Dispatched From Assign Role File
    // Data to send { CompanyID, Array of Permissions[], RoleName }
    createRole: ({ commit, getters }, body) => {
      commit("setRole", {
        obj: body,
        roles: getters.getRoles,
      });
    },
    // Delete Role From Comopany
    // This Action is Dispatched From RolePannel File
    // Data to send { CompanyID, RoleName }
    deleteRole: ({ rootGetters }, data) => {
      companyAPI.deleteRole(
        rootGetters.getSelectedCompany._id,
        data.roleName,
        (status) => {
          if (status == 200) {
            debug.log("Role deleted");
          }
        }
      );
    },
    // AssignRoleToUser
    // This Action is Dispatched From AddRolesModal File
    // Data to send { CompanyID, userID, RoleName }
    assignRoleToUser: (context, obj) => {
      context.commit("ASSIGN_ROLE", {
        obj,
        users: context.getters.getSelectedCompanyUsers,
      });
      companyAPI.assignRoleToUser(
        obj.companyId,
        obj.userId,
        obj.roleName,
        (status) => {
          if (status == 200) {
            debug.log("successfully assign Role To User");
          }
        }
      );
    },

    updateUsers({ commit, getters }, user) {
      commit("UPDATE_USER", {
        allUsers: getters.getSelectedCompanyUsers,
        user,
      });
    },

    //   Set Assignee
    setAssignee: ({ commit, getters }, data) => {
      let role = getters.getRoles.find((item) => {
        return item.roleName == data.radioGroupvalue;
      });

      let obj = {
        role,
        name: data.user.name,
      };

      commit("roleAssignee", obj);
    },

    setLink: (context) => {
      context.commit("SET_LINK", {
        permissions: context.rootGetters.getSelectedCompany.permissions,
        role: context.rootGetters.getSelectedCompany.role_name,
      });
    },
  },
  modules = {
    companyStore,
    teamStore,
    userStore,
    channelStore,
    departmentStore,
    customAppsStore,
  };

export default {
  state,
  getters,
  mutations,
  actions,
  modules,
};
