import axios from "axios";
import router from "@/router";
import debug from "@/console";

const state = {
  signedUp: null,
};
const actions = {
  //To Register a user in company
  register(context, body) {
    debug.log("inregister");
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BASE_URL + "/signup", body)
        .then((response) => {
          if (response.status == 200) {
            context.commit("SET_SIGNED_UP", body);
            router.push("/thankyou");
            resolve(response);
          }
        })
        .catch((error) => {
          debug.log("in error", error);
          reject(error);
        });
    });
  },
};
const mutations = {
  // Set Newly Rigister User's Data
  SET_SIGNED_UP(state, data) {
    state.signedUp = data;
  },
};
const getters = {
  getSignedUp: (state) => state.signedUp,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
