/*
System: Whistle It
Developer: Ahmad Hannan
Organization: Whistle It
Purpose: This file is used for making api calls for   messages
*/
import requestQueue from "@/service/requestQueue";
import debug from "@/console";
var requestBody;
var requestEndPoint;
var token;

const messageAPI = {
  /* Message CRUD */

  /*
This function is responsible for making api call for send Message
args:(channel_id,files,audio,video,message,send_after), callback function for done
*/
  sendMessage: (
    channel_id,
    team_id,
    audio,
    video,
    message,
    is_important,
    send_after,
    files,
    mention_users,
    temp_id,
    replying_id,
    replying_message,
    user_time,
    support_message = false,
    announcement = false,
    shared_company_ids,
    shared_team_ids,
    shared_users_ids,
    poll_id,
    zoom,
    isInvited,
    secret_key,
    emoji,
    gifs_data,
    messageBody,
    done
  ) => {
    requestBody = {
      channel_id,
      team_id,
      files,
      audio,
      video,
      message,
      is_important,
      mention_users,
      temp_id,
      replying_id,
      replying_message,
      user_time,
      support_message,
      announcement,
      shared_company_ids,
      shared_team_ids,
      shared_users_ids,
      poll_id,
      zoom,
      secret_key,
      emoji,
      gifs_data,
    };
    if (send_after) {
      requestBody.send_after = send_after;
    }

    if (isInvited) {
      requestEndPoint = "message/sendGuestMessage";
    } else {
      requestEndPoint = "message/create";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(
      requestEndPoint,
      token,
      { requestBody, messageBody },
      done
    );
  },
  completeMessageRemind(requestBody, done) {
    requestEndPoint = "reminder/deleteReminderMessage";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  remindMessageApi(requestBody, done) {
    debug.log("Message body API", requestBody);
    requestEndPoint = "reminder/create";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  updateRemindMessage(requestBody, done) {
    debug.log("Message body API", requestBody);
    requestEndPoint = "reminder/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  deleteRemindMsgApi(requestBody, done) {
    debug.log("Message body API", requestBody);
    requestEndPoint = "reminder/delete";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  getRemindersByAPI(requestBody, done) {
    requestEndPoint = "reminder/read";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for uploading files
args:(company_id, team_id, message_id, receiver_id[], channel_id[]), callback function for done
*/
  uploadFiles: (data, done) => {
    requestBody = data.files;
    requestEndPoint = "uploadFiles";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(
      requestEndPoint,
      token,
      data.files,
      done,
      {
        data: data.progress,
        index: data.index,
      },
      data.cancelToken
    );
  },
  /*
This function is responsible for making api call for removing uploaded files from server
args:(file), callback function for done
*/
  removeFileFromServer: (file, channel_id, isInvited, secret_key, done) => {
    requestBody = {
      channel_id,
      attachments: file,
    };
    if (isInvited) {
      requestBody.secret_key = secret_key;
      requestEndPoint = "removeGuestFiles";
    } else {
      requestEndPoint = "removeFiles";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for Forward Message
args:(company_id, team_id, message_id, receiver_id[], channel_id[]), callback function for done
*/
  forwardMessage: (user_time, message_id, channel_ids, include_thread, original_sender, team_id, done) => {
    requestBody = {
      user_time,
      message_id,
      channel_ids,
      include_thread,
      original_sender,
      team_id,
    };
    requestEndPoint = "message/forward";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for Search Message
args:(company_id, team_id, receiver_id, channel_id,search_text), callback function for done
*/
  searchMessage: (
    channel_id,
    search,
    start_date,
    end_date,
    search_in_webhook,
    from,
    page,
    isInvited,
    secret_key,
    done
  ) => {
    requestBody = {
      channel_id,
      search: search,
      start_date,
      end_date,
      search_in_webhook,
      from,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/searchGuestMessage?page=" + page;
    } else {
      requestEndPoint = "message/search?page=" + page;
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for Search Message
args:(company_id, team_id, receiver_id, channel_id,search_text), callback function for done
*/
  readConflictMessage: (
    channel_id,
    sender_id,
    temp_id,
    isInvited,
    secret_key,
    done
  ) => {
    requestBody = {
      channel_id,
      sender_id,
      temp_id,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestMessage";
    } else {
      requestEndPoint = "message/read";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  /*
This function is responsible for making api call for Search Message to jump specific message
args:(message_id), callback function for done
*/
  specificMessage: (channel_id, message_id, isInvited, secret_key, done) => {
    requestBody = {
      channel_id,
      search_message_id: message_id.messageId,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestMessage";
    } else {
      requestEndPoint = "message/read";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for message pagination
args:( company_id,
    team_id,
    message_id,
    deleted_at), callback function for done
*/
  messagePagination: (
    channel_id,
    paginate_message_id,
    paginate,
    isInvited,
    secret_key,
    done
  ) => {
    requestBody = {
      channel_id,
      paginate_message_id,
      paginate,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestMessage";
    } else {
      requestEndPoint = "message/read";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for get messages 
args:( 
   channel_id,
   get_messages = true (incase first time data fetch for particular channel)
), callback function for done
*/
  getMessagesFromAPI: (
    channel_id,
    get_messages,
    isInvited,
    secret_key,
    done
  ) => {
    requestBody = {
      channel_id,
      get_messages,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestMessage";
    } else {
      requestEndPoint = "message/read";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
This function is responsible for making api call for message upation(i.e. read messages, edit, delete and schedule delete)
args:( message_id, channel_id, message, delete_after, delete_at, is_read, done), callback function for done
*/
  updateMessage: (
    message_id,
    channel_id,
    message,
    delete_after,
    delete_at,
    is_read,
    single_read,
    react,
    reaction,
    isInvited,
    secret_key,
    emoji,
    done
  ) => {
    requestBody = {
      message_id,
      channel_id,
      message,
      delete_after,
      delete_at,
      is_read,
      single_read,
      react,
      reaction,
      secret_key,
      emoji,
    };
    if (isInvited) {
      requestEndPoint = "message/updateGuestMessage";
    } else {
      requestEndPoint = "message/update";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Get Pinned Messages
  pinnedMessages: (
    channel_id,
    pinned_messages,
    isInvited,
    secret_key,
    done
  ) => {
    requestBody = {
      channel_id,
      pinned_messages,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestMessage";
    } else {
      requestEndPoint = "message/read";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  replyedMessages(channel_id, message_id, isInvited, secret_key, done) {
    requestBody = {
      channel_id,
      message_id,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/getGuestReplies";
    } else {
      requestEndPoint = "message/getReplies";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  markSceanAllReplies(channel_id, message_ids, isInvited, secret_key, done) {
    requestBody = {
      channel_id,
      message_ids,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readAllGuestThreadReplies";
    } else {
      requestEndPoint = "message/readAllThread";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  sceenReply(channel_id, message_id, isInvited, secret_key, done) {
    requestBody = {
      channel_id,
      message_id,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/readGuestThreadReply";
    } else {
      requestEndPoint = "message/readThread";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  // Pin a specific message
  // Get Pinned Messages
  pinMessage: (message_id, channel_id, pin, isInvited, secret_key, done) => {
    requestBody = {
      message_id,
      channel_id,
      pin,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/updateGuestMessage";
    } else {
      requestEndPoint = "message/update";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  setDeleteFileStatus(
    message_id,
    channel_id,
    delete_attachments,
    isInvited,
    secret_key,
    done
  ) {
    requestBody = {
      message_id,
      channel_id,
      delete_attachments,
      secret_key,
    };
    if (isInvited) {
      requestEndPoint = "message/updateGuestMessage";
    } else {
      requestEndPoint = "message/update";
    }
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  sendPollMessage(data, done) {
    requestBody = {
      channel_id: data.channel_id,
      title: data.title,
      description: data.description,
      start_time: data.start_time,
      end_time: data.end_time,
      result_visibility: data.result_visibility,
      response_visibility: data.response_visibility,
      questions: data.questions,
      company_id: data.company_id,
    };
    requestEndPoint = "poll/create";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  submitPollResponse(data, done) {
    requestBody = {
      channel_id: data.channel_id,
      poll_id: data.poll_id,
      answers: data.answers,
    };
    requestEndPoint = "poll/answer";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  getPollResult(data, done) {
    requestBody = {
      channel_id: data.channel_id,
      poll_id: data.poll_id,
    };
    requestEndPoint = "poll/get_result";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  getPollQuestions(data, done) {
    requestBody = {
      poll_id: data.poll_id,
    };
    requestEndPoint = "poll/get_questions";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  PollClose(data, done) {
    requestBody = {
      channel_id: data.channel_id,
      poll_id: data.poll._id,
      poll_status: "closed",
    };
    requestEndPoint = "poll/update";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  exportToCSV(data, done) {
    requestBody = {
      poll_id: data.poll._id,
    };
    requestEndPoint = "poll/export_to_csv";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  getPollsByAPI(requestBody, done) {
    requestEndPoint = "poll/get_polls";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  globalSearchAPI(requestBody, done) {
    requestEndPoint = "globalSearch";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  searchFileApi(requestBody, done) {
    requestEndPoint = "searchFiles";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  getPublicShareableUrl(requestBody, done) {
    requestEndPoint = "getPublicShareableUrl";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /* 
This function is responsible for making api call for notification message data(i.e. message, sender name,and sender profile)
args:( requestBody, done), callback function for done
*/
  getNotificationChannelData(requestBody, done) {
    requestEndPoint = "message/getNotificationChannelData";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
  This function is responsible for making api call to reply from quick reply component
args:( requestBody, done), callback function for done
*/
  replyMessageFromQuickReply(requestBody, done) {
    requestEndPoint = "message/create";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
 Sends a request to retrieve unread notifications using the provided request body and executes the callback function when done.
*/
  unreadMessageRequest(requestBody, done) { 
    requestEndPoint = "getRecentChats";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /*
  Sends a request to mark all notification messages as read using the provided request body and executes the callback function when done.
*/
  readAllMessageRequest(requestBody, done) {
    requestEndPoint = "read_all_notification_messages";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },

  markAllMessageRead(requestBody, done) {
   
    requestEndPoint = "markWorkspaceAsRead";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  markMessageRead: (company_id, channel_id, done) => {
    requestBody = {
      company_id,
      channel_id,
    };

    requestEndPoint = "ages";

    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
  /**
  This function is responsible for making api call for get recent files 
  args:( 
    company_id,
  ), callback function for done
  */
  getRecentAttachmentsFromApi: (company_id, done) => {
    requestBody = {
      company_id,
    };
    requestEndPoint = "getRecentFiles";
    token = JSON.parse(localStorage.getItem("token"));
    requestQueue.post(requestEndPoint, token, requestBody, done);
  },
};

export default messageAPI;
