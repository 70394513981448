/**
 * System: Whistle It
 * Developer: Nabil Ahmad
 * Organization: Whistle It
 * Purpose: This file is for calling API for user's operations on channel like channel information updation, join channel, leave channel and mute channel etc.
 */
import channelAPI from "@/api/channelAPI";
import teamAPI from "@/api/teamAPI";
import debug from "@/console";
import messageAPI from "@/api/messageAPI";
//verify if logged-in user has udate permissions in team
let isTeamSubAdmin = (permissionsObj) => {
  if (permissionsObj.team) {
    return permissionsObj.team.includes("update");
  } else {
    return false;
  }
};
let isChannelsSubAdmin = (permissionsObj) => {
  if (permissionsObj.channel) {
    return permissionsObj.channel.includes("update");
  } else {
    return false;
  }
};
const state = {
  prevDispChannels: [],
};
const getters = {
  getPreDispChannels: (state) => state.prevDispChannels,
  /**
   * this getter returns the users detail from company users which you want by providing their ids
   */
  getUsersDetail: (state, getters, rootState, rootGetters) => (users) => {
    let usersDetail = [];
    for (let userId of users) {
      for (const compUserId of rootGetters.getSelectedCompany.users) {
        if (compUserId._id == userId && compUserId.archived == null) {
          usersDetail.push(compUserId);
          break;
        }
      }
    }
    return usersDetail;
  },
  /**
   * this getter filter loggedIn user from selected channel members and then returns the detail of other members
   */
  getChannelMembersDetail: (state, getters, rootState, rootGetters) => {
    let channelMembers = rootGetters.getSelectedChannel.users.filter(
      (item) => item != rootGetters.loggedInUserDetails._id
    );
    return getters.getUsersDetail(channelMembers);
  },
  /**
   * this getter returns the users detail from company users which not joined selected channel
   */
  getNonChannelMembers: (state, getters, rootState, rootGetters) => {
    let nonChannelMemberIds = [];
    if (rootGetters.getSelectedChannel.type != "support") {
      nonChannelMemberIds = rootGetters.getSelectedTeam.users.filter(
        (teamUserId) => {
          //if user id is not in channel users id then return that user id
          if (
            !rootGetters.getSelectedChannel.users.some(
              (channelUserId) => channelUserId == teamUserId
            )
          ) {
            return teamUserId;
          }
        }
      );
    } else {
      nonChannelMemberIds = rootGetters.getSelectedCompany.users.filter(
        (compUser) => {
          //if user id is not in channel users id then return that user id
          if (
            compUser.archived == null &&
            !rootGetters.getSelectedChannel.users.some(
              (channelUserId) => channelUserId == compUser._id
            )
          ) {
            return compUser;
          }
        }
      );
      //get non channel members detail from company users
      return nonChannelMemberIds;
    }
    //get non channel members detail from company users
    return getters.getUsersDetail(nonChannelMemberIds);
  },
};
const actions = {
  // Join existing public channel
  // Note that private channels can't be joined
  // User is added by creator of private channel
  joinChannel({ commit, dispatch, rootGetters }, channel) {
    return new Promise((resolve, reject) => {
      let loginUserId = rootGetters.loggedInUserDetails._id;
      //if user join channel by command + K
      if (channel.byCommandJoin) {
        channel = rootGetters.getSelectedCompany.channels.find(
          (singleChannel) => singleChannel._id == channel._id
        );
      }
      var done = async (response) => {
        if (response.status == 200) {
          commit("setChannelJoined", {
            channel: channel,
            loginUser: loginUserId,
            newChannelData: response.data.channel,
          });
          //clear previous states
          await dispatch("resetStates");

          //select joined channel
          commit("setSelectedChannel", {
            _id: channel._id,
            type: channel.type,
          });
          //setting route according to new joined channel
          dispatch("changeHomeRoute");
          //if there is new_message_count then make api call and remove new_message_count
          dispatch("clearMessageRead");
          resolve(response);
        } else {
          reject(response);
        }
      };

      // Calls API with done
      channelAPI.addUserInChannel(
        [rootGetters.loggedInUserDetails._id],
        channel._id,
        done
      );
    });
  },

  // Leave joined team
  // Leave joined team
  leaveTeam({ commit, getters, dispatch, rootGetters }) {
    let teamDetail = getters.getSelectedTeam;
    let company = rootGetters.getSelectedCompany;
    var done = async () => {
      // set first join team as selected
      if (rootGetters.getJoinedTeams.length > 1) {
        await dispatch(
          "selectTeam",
          getters.getSelectedCompany.teams.find(
            (team) =>
              team.joined == true &&
              team.archived == null &&
              team._id != teamDetail._id
          )._id
        );
      }
      //leave team
      commit("setTeamLeave", {
        company,
        teamDetail,
        isTeamAdmin: company.role_name == "admin",
        isTeamSubAdmin: isTeamSubAdmin(company.permissions),
        loginUserId: rootGetters.loggedInUserDetails._id,
      });
    };
    // Calls API with done
    teamAPI.leaveTeam(
      [rootGetters.loggedInUserDetails._id],
      teamDetail._id,
      done
    );
  },
  // CHANNELS UPDATE
  // Edit channel name
  changeChannelName({ commit, rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          commit("setChannelName", {
            channel: payload.channel,
            name: payload.name,
            description: payload.description,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };

      // Calls API with done
      channelAPI.editChannelInfo(
        payload.name,
        payload.description,
        rootState.selectedChannel._id,
        rootGetters.getSelectedCompany._id,
        rootGetters.getSelectedTeam._id,
        done
      );
    });
  },

  markAllMessagesAsRead({ commit, rootGetters }, payload) {

    return new Promise((resolve, reject) => {
      messageAPI.markAllMessageRead(payload, (response) => {
        if (response.status == 200) {
          const filterDirect = rootGetters.getSelectedCompany.direct.filter(channel =>
            channel.user_ids.includes(payload.user_id))

          const filterChannels = rootGetters.getSelectedCompany.channels.filter(channel =>
            channel.user_ids.includes(payload.user_id))

          resolve(response);
          commit('allMarkMessagesAsRead', {
            payload,
            filterChannels,
            filterDirect,
          });
        } else {
          reject(response.error);
        }
      });
    });
  },
  //Archive Channel
  archiveChannel({ commit, dispatch, rootGetters, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let company = rootGetters.getSelectedCompany;
      var done = async (response) => {
        if (response.status == 200) {
          //select default public channel
          let selectedChannel = rootGetters.getSelectedCompany.channels.find(
            (channel) =>
              channel.team_id == rootGetters.getSelectedTeam._id &&
              channel.default == true
          );
          await dispatch("selectChannel", {
            _id: selectedChannel._id,
            type: selectedChannel.type,
          });
          commit("setArchivedChannel", {
            channel: payload.channel,
            company,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };

      channelAPI.archiveChannel(
        rootGetters.getSelectedTeam._id,
        rootState.selectedChannel._id,
        payload.date,
        done
      );
    });
  },

  /**
   * This action is responsilbe to upate mute status of channel
   *
   * @param {object} payload The payload object contains further two objects one is company and other is channel on which user click.
   * @return promise
   */
  muteChannel({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let updatedStatusValue = !payload.channel.muted;
      var done = (response) => {
        if (response.status == 200) {
          commit("updateMuteFavUnReadStatus", {
            ...payload,
            changeKey: "muted",
            updatedStatusValue,
            muted_channel_timer: payload.muted_channel_timer,
          });
          resolve();
        } else {
          reject();
        }
      };
      // Calls API with done
      channelAPI.muteChannel(payload.channel._id, !payload.channel.muted, payload.mute_timer, done);
    });
  },

  /**
   * This action is responsilbe to upate flag status of channel
   *
   * @param {object} payload The payload object contains further two objects one is company and other is channel on which user click.
   * @return promise
   */
  flagChannel({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let updatedStatusValue = !payload.channel.flagged;
      var done = (response) => {
        if (response.status == 200) {
          commit("updateMuteFavUnReadStatus", {
            ...payload,
            changeKey: "flagged",
            updatedStatusValue,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      // Calls API with done
      channelAPI.flagChannel(
        payload.channel._id,
        !payload.channel.flagged,
        done
      );
    });
  },
  // Add Users to Channel
  addUsersToChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          commit("addMembers", {
            usersToAdd: payload.uids,
            channel: payload.channel,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };

      channelAPI.addUserInChannel(payload.uids, payload.channel._id, done);
    });
  },

  // Remove Users from Channel
  removeUsersFromChannel({ commit }, payload) {
    var done = (response) => {
      debug.log("Store response", response);
      if (response.status === 200) {
        commit("removeMembers", {
          usersToRemove: payload.user_ids,
          channel: payload.channel,
        });
      } else {
        debug.log("error");
      }
    };

    channelAPI.removeUsersFromChannel(
      payload.user_ids,
      payload.channel._id,
      done
    );
  },

  // Leave channel
  leaveChannel({ commit, rootGetters, dispatch }, payload) {
    let team = rootGetters.getSelectedTeam;
    let company = rootGetters.getSelectedCompany;
    let userId = rootGetters.loggedInUserDetails._id;
    var done = (response) => {
      debug.log("Store response", response);
      if (response.status === 200) {
        payload.loggedInUser = userId;
        payload.company = company;
        payload.teamId = team._id;
        commit("setChannelLeave", payload);
        debug.log(response.status);
      } else {
        debug.log("error");
      }
    };
    // Calls API with done
    channelAPI.leaveChannel(
      payload.user_ids,
      payload.channel._id,
      payload.creator,
      done
    );

    let selectedChannel = company.channels.find(
      (channel) => channel.default == true && channel.team_id == team._id
    );

    dispatch("selectChannel", {
      _id: selectedChannel._id,
      type: selectedChannel.type,
    });
  },
  //Read only
  readOnly(context, payload) {
    return new Promise((resolve, reject) => {
      let channel = context.rootGetters.getSelectedChannel;
      var done = (response) => {
        if (response.status == 200) {
          context.commit("setReadOnlyStatus", {
            channel,
            readOnly: payload.readOnly,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.readOnly(
        context.rootState.selectedChannel._id,
        context.rootGetters.getSelectedTeam._id,
        payload.readOnly,
        payload.user_ids,
        payload.allowed_senders,
        done
      );
    });
  },
  //Read only allowed users ID
  readOnlySelectedUser(context, payload) {
    return new Promise((resolve, reject) => {
      let channel = context.rootGetters.getSelectedChannel;
      var done = (response) => {
        if (response.status == 200) {
          context.commit("setReadOnlyAllowedUser", {
            channel,
            allowedUsers: payload.readOnlyAllowedUsers,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.readonlyAllowedusers(
        context.rootState.selectedChannel._id,
        context.rootGetters.getSelectedTeam._id,
        payload.allowed_senders,
        payload.readOnlyAllowedUsers,
        payload.read_only,
        done
      );
    });
  },
  /**
   * This action is responsilbe to mark as read channel
   *
   * @param {object} payload The payload object contains further two objects one is company and other is channel on which user click.
   * @return promise
   */
  readMessages({ getters, rootState, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          if (payload.channel.type == "support") {
            // set count of new messages to zero of selected channel
            commit("supportChannelMsgCount", {
              ...payload,
              isNewMsg: false,
            });
          } else {
            // set count of new messages to zero of selected channel
            commit("markAsReadMessages", payload);
          }
          resolve(response);
        } else {
          reject(response);
        }
      };
      //if there is any new message in channel then call message read api
      if (payload.channel.new_message_count) {
        messageAPI.updateMessage(
          undefined,
          payload.channel._id,
          undefined,
          undefined,
          undefined,
          1,
          undefined,
          undefined,
          undefined,
          payload.channel.invited,
          payload.channel.secret_key,
          undefined,
          done
        );
      }
      //if there is unread_channel key true then false it to make API call
      if (payload.channel.unread_channel) {
        dispatch("updateMarkAsUnread", payload).then(() => {
          resolve();
        });
      }
      //if there is any reply message in channel then read all replies
      if (payload.channel?.thread_child_messages?.length) {
        let parent_ids = [];
        for (let message of payload.channel.thread_child_messages) {
          parent_ids.push(message.parent._id);
        }
        messageAPI.markSceanAllReplies(
          payload.channel._id,
          parent_ids,
          payload.channel.invited,
          payload.channel.secret_key,
          done
        );
      }
    });
  },
  /**
   * This method is responsible to update connection/secret url
   *
   * @param {object} channel The seclected channel object
   * @return Promise
   */
  updateSecretKey({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          commit("updateSecretKey", {
            channel: payload.channel,
            newURL: response.data.connect_url,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.updateSecretKey(payload.channel._id, done);
    });
  },
  /**
   * This method is responsible to make existing channel to cloud channel
   *
   * @param {object} channel The seclected channel object
   * @return Promise
   */
  convertChannelToCloud({ commit, rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      var done = async (response) => {
        if (response.status == 200) {
          commit("cloudConversionUpdate", {
            channel: payload.channel,
            newData: response.data.data,
          });
          let messages = {};
          messages[payload.data.channel_id] = undefined;
          //remove already visited channel messages
          commit("setMessages", {
            messages,
            channel_id: payload.data.channel_id,
          });
          await dispatch("getMessagesFromAPI");
          resolve(response);
        } else {
          reject(response);
        }
      };
      channelAPI.convertChannelToCloud(payload.data, done);
    });
  },
  /**
   * This method is responsible to get cloud channels count
   *
   * @return void
   */
  getCloudChannelsMsgsCount(
    { commit, dispatch, getters, rootState, rootGetters },
    payload
  ) {
    var done = async (response) => {
      if (response.status == 200) {
        for (const channelData of response.data.channels.data) {
          if (channelData.channel) {
            if (channelData.channel_id == rootState.selectedChannel?._id) {
              //save messages
              for (const message of channelData.channel.messages) {
                await dispatch("callSocketsActions", {
                  name: "socket_newMessage",
                  message,
                });
              }
              //save thread messages
              for (const message of channelData.channel.thread_child_messages) {
                await dispatch("callSocketsActions", {
                  name: "socket_newReplyMessage",
                  message,
                });
              }
            } else {
              //find channel detail
              let storeChannelDetail = await rootGetters.getMessagesDetail(
                channelData
              );
              if (storeChannelDetail?.channel) {
                if (
                  storeChannelDetail.channel.new_message_count <
                  channelData.new_message_count ||
                  storeChannelDetail.channel?.thread_child_messages?.length <
                  channelData?.thread_child_messages?.length
                ) {
                  let messages = {};
                  messages[channelData.channel_id] = undefined;
                  //remove already visited channel messages
                  commit("setMessages", {
                    messages,
                    channel_id: channelData.channel_id,
                  });
                  //count update new message
                  commit("updateCountOfChannel", {
                    channel: storeChannelDetail.channel,
                    newData: channelData,
                  });
                }
              }
            }
          }
        }
        if (
          response.data.channels.current_page < response.data.channels.last_page
        ) {
          dispatch("getCloudChannelsMsgsCount", {
            page: ++response.data.channels.current_page,
            channel_id: rootGetters.getSelectedChannel?._id,
          });
        } else {
          setTimeout(() => {
            dispatch("getCloudChannelsMsgsCount", {
              page: 1,
              channel_id: rootGetters.getSelectedChannel?._id,
            });
          }, 60000);
        }
      } else {
        setTimeout(() => {
          dispatch("getCloudChannelsMsgsCount", {
            page: 1,
            channel_id: rootGetters.getSelectedChannel?._id,
          });
        }, 60000);
      }
    };
    channelAPI.getCloudChannelsMsgsCount(payload, done);
  },
  /**
   * This method is responsible to remove guest users
   *
   * @return promise
   */
  fetchGuestWorkspaces(context, payload) {
    return new Promise((resolve, reject) => {
      var done = async (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      payload.company_id = context.rootGetters.getSelectedCompany._id;
      payload.team_id = context.rootGetters.getSelectedTeam._id;
      channelAPI.fetchGuestWorkspaces(payload, done);
    });
  },
  /**
   * This method is responsible to remove guest users
   *
   * @return promise
   */
  removeGuestFromChannel(context, payload) {
    return new Promise((resolve, reject) => {
      var done = async (response) => {
        if (response.status == 200) {
          context.commit("removeGuestCompany", {
            channel: payload.channel,
            removeId: payload.data.guest_channel_id,
          });
          resolve(response);
        } else {
          reject(response);
        }
      };
      payload.data.company_id = context.rootGetters.getSelectedCompany._id;
      payload.data.team_id = context.rootGetters.getSelectedTeam._id;
      channelAPI.removeGuestFromChannel(payload.data, done);
    });
  },
};
const mutations = {
  addNewDirectChannel: (state, payload) => {
    payload.directChannels.push(payload.newDirectChannel);
  },
  // CHANNELS MUTATIONS

  // CHANNELS -- CREATE
  // Temporary
  addChannel: (state, payload) => {
    debug.log("Add channel mutation", payload);
    //add common fields of new created channel in case of direct and public/private
    payload.channel.default = false;
    payload.channel.muted = false;
    payload.channel.archived = null;
    payload.channel.joined = true;
    payload.channel.invited = false;
    payload.channel.pinned = false;
    payload.channel.new_message_count = 0;
    payload.channel.messages = [];
    payload.channel.is_displayed = true;
    payload.channel.thread_child_messages = [];
    payload.channel.last_message_at = new Date();
    payload.channel.pinned_messages = false;
    payload.channel.flagged = false;
    if (payload.channel.type == "group" || payload.channel.type == "direct") {
      payload.company.direct.unshift(payload.channel);
    } else {
      payload.company.channels.unshift(payload.channel);
    }
  },
  // After response
  addChannelResponse(state, payload) {
    let channelDetail;
    if (payload.type == "group" || payload.type == "direct") {
      channelDetail = payload.company.direct.find((channel) => {
        if (
          (channel.users.length == payload.newData.users.length &&
            channel.users.filter(
              (channelUserId) => !payload.newData.users.includes(channelUserId)
            ).length == 0) ||
          channel._id == payload.tempId ||
          channel._id == payload.newData._id
        ) {
          return channel;
        }
      });
    } else {
      channelDetail = payload.company.channels.find(
        (channel) =>
          channel._id == payload.tempId || channel._id == payload.newData._id
      );
    }
    Object.assign(channelDetail, payload.newData);
    channelDetail.is_displayed = true;
  },
  //if channel creation is failed then remove that channel
  addChannelFailure: (state, payload) => {
    let channelIndex = -1;
    if (payload.channel.type == "group") {
      channelIndex = payload.company.direct.findIndex(
        (channel) => channel._id == payload.channel._id
      );
      if (channelIndex != -1) {
        payload.company.direct.splice(channelIndex, 1);
      }
    } else {
      channelIndex = payload.company.channels.findIndex(
        (channel) => channel._id == payload.channel._id
      );
      if (channelIndex != -1) {
        payload.company.channels.splice(channelIndex, 1);
      }
    }
  },

  // CHANNELS -- UPDATE
  // Set/unset mute, flag and unread status
  updateMuteFavUnReadStatus: (state, payload) => {
    let findedChannel;
    if (payload.channel.type == "group" || payload.channel.type == "direct") {
      findedChannel = payload.company.direct.find(
        (channel) => channel._id == payload.channel._id
      );
    } else {
      findedChannel = payload.company.channels.find(
        (channel) => channel._id == payload.channel._id
      );
    }
    if (findedChannel) {
      if (payload.changeKey == "muted") {
        findedChannel.muted_channel_timer = payload.muted_channel_timer;
      }
      findedChannel[payload.changeKey] = payload.updatedStatusValue;
    }
  },
  // Change channel name
  setChannelName: (state, payload) => {
    payload.channel.name = payload.name ? payload.name : payload.channel.name;
    payload.channel.description = payload.description
      ? payload.description
      : payload.channel.description;
  },

  //Set Archived Channel
  setArchivedChannel: (state, payload) => {
    //if user then remove channel
    let channelIndex = payload.company.channels.findIndex(
      (channel) => channel._id == payload.channel._id
    );
    if (channelIndex > -1) {
      payload.company.channels.splice(channelIndex, 1);
    }
  },

  // Add members to channel
  addMembers: (state, payload) => {
    if ("users" in payload.channel) {
      for (let uid of payload.usersToAdd) {
        if (!payload.channel.users.includes(uid)) {
          payload.channel.users.push(uid);
        }
      }
    }
  },

  //Remove members from channel
  removeMembers: (state, payload) => {
    if ("users" in payload.channel) {
      for (const userId of payload.usersToRemove) {
        let index = payload.channel.users.indexOf(userId);
        if (index > -1) {
          payload.channel.users.splice(index, 1);
        }
      }
    }
  },

  // Join channel
  setChannelJoined(state, data) {
    if (!data.channel.joined) {
      Object.assign(data.channel, data.newChannelData);
    }
  },

  // Leave public channel
  setPublicChannelLeaved(state, channel) {
    channel.joined = false;
  },

  //Leave team
  setTeamLeave(state, payload) {
    // remove all channels of specific team from where user is removed
    payload.company.channels = payload.company.channels.filter(
      (channel) =>
        (channel.type != "support" &&
          channel.team_id != payload.teamDetail._id) ||
        (channel.type == "support" &&
          (channel?.team_ids?.length == 0 ||
            (channel?.team_ids?.length == 1 &&
              !channel.team_ids.includes(payload.teamDetail._id))))
    );
    //if team admin then only set joined key
    if (payload.isTeamSubAdmin || payload.isTeamAdmin) {
      payload.teamDetail.joined = false;
      //remove user id from team users
      payload.teamDetail.users = payload.teamDetail.users.filter(
        (userId) => userId != payload.loginUserId
      );
    } else {
      let teamIndex = payload.company.teams.findIndex(
        (team) => team._id == payload.teamDetail._id
      );
      payload.company.teams.splice(teamIndex, 1);
    }
  },
  //set Leave Channel
  setChannelLeave(state, payload) {
    //if a user who wants to leave channel is creator then set value of new creator
    if (payload.creator != undefined || payload.creator != null) {
      payload.channel.creator_id = payload.creator;
    }
    if (payload.channel.type == "public" && payload.channel.users.length > 1) {
      payload.channel.users = payload.channel.users.filter(
        (user) => user != payload.loggedInUser
      );
      payload.channel.joined = false;
      payload.channel.messages = [];
    } else {
      let channelIndex = payload.company.channels.findIndex(
        (channel) => channel._id == payload.channel._id
      );

      //if a user which wants to leave channel is admin and only single member is in channel then not remove private channel from array
      //only update some fileds
      if (channelIndex != -1) {
        payload.company.channels.splice(channelIndex, 1);
      }
    }
  },
  setReadOnlyStatus: (state, payload) => {
    payload.channel.all_users_restricted = payload.readOnly;
    payload.channel.allowed_senders = [];
  },

  setReadOnlyAllowedUser: (state, payload) => {
    payload.channel.allowed_senders = payload.allowedUsers;
    payload.channel.all_users_restricted = true;
  },
  //Find channel from type of private, public and direct and update key on the basis of parameter
  markAsReadMessages: (state, payload) => {
    let channelType =
      payload.channel.type != "direct" && payload.channel.type != "group"
        ? "channels"
        : "direct";
    //remove new message count of selected channel from company new_message_count
    payload.company.new_message_count -= payload.channel.new_message_count;
    if (channelType == "channels") {
      //find team and remove new message count of selected channel from team new_message_count
      let teamDetail = payload.company.teams.find(
        (team) => team._id == payload.channel.team_id
      );
      if (teamDetail) {
        teamDetail.new_message_count -= payload.channel.new_message_count;
      }
    }
    let findedChannel = payload.company[channelType].find(
      (channel) => channel._id == payload.channel._id
    );
    if (findedChannel) {
      findedChannel.new_message_count = 0;
      findedChannel.thread_child_messages = [];
    }
  },
  allMarkMessagesAsRead: (state, { payload, filterChannels, filterDirect }) => {

    const unreadMsgsChannels = filterChannels.filter(channel => channel.new_message_count > 0)
    const unreadMsgsDirects = filterDirect.filter(direct => direct.new_message_count > 0)


    if (unreadMsgsChannels) {
      unreadMsgsChannels.forEach(channel => {
        channel.new_message_count = 0;
      })
      if (unreadMsgsDirects) {
        unreadMsgsDirects.forEach(channel => {
          channel.new_message_count = 0;
        });
      }
    }
  },

  //update connection/secret url
  updateSecretKey: (state, payload) => {
    payload.channel.connect_url = payload.newURL;
  },
  updatePreDispChannels(state, payload) {
    state.prevDispChannels = payload;
  },
  //convert channel to cloud
  cloudConversionUpdate(state, payload) {
    payload.channel.invited = true;
    payload.channel.connect_url = null;
    payload.channel.secret_key = payload.newData.secret_key;
    payload.channel.host_data = payload.newData.host_data;
  },
  removeGuestCompany(state, payload) {
    payload.channel.guests = payload.channel.guests.filter(
      (user) => user.guest_channel_id != payload.removeId
    );
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
