/**
 * System: Whistle It
 * Developer: Safeer Baig and Nabil Ahmad
 * Organization: Whistle It
 * Purpose: This file is for listening and changing updated data in messages store.
 */

import messageAPI from "@/api/messageAPI";
import Vue from "vue";
import router from "@/router";
/**
 * this function takes the data and set this data in the message and return message object
 * args:(data)
 */
let stopTimer;

let messageBody = function (data) {
  let messageBody = {
    _id: data._id,
    sender_id: data.sender_id,
    is_forwarded: data.is_forwarded ? true : false,
    is_read: [],
    deleted_at: null,
    user_time: data.user_time,
    created_at: data.created_at,
    message: data.message,
    pinned_by: data.pinned_by,
    replied_ids: data?.replied_ids?.length ? data.replied_ids : [],
    replying_id: data.replying_id ? data.replying_id : null,
    replied_sender_id: data.replied_sender_id ? data.replied_sender_id : null,
    replied_created_at: data.replied_created_at
      ? data.replied_created_at
      : null,
    reactions: data?.reactions?.length ? data.reactions : [],
    poll_id: "",
    poll_data: {},
    emoji: false,
    gifs_data: data?.gifs_data?.length ? data.gifs_data : [],
    maxRetry: false,
    hook_message: data.hook_message,
    is_important: data?.is_important ? data.is_important : false,
    original_sender_id: data.original_sender_id,
    original_sender_name: data.original_sender_name
  };

  messageBody.reminded_by = data.reminded_by ? data.reminded_by : [];
  if (data.webhook_table !== undefined) {
    messageBody.webhook_table = data.webhook_table;
  }
  if (data.send_after) messageBody.send_after = data.send_after;
  if (data.webhook_message !== undefined)
    messageBody.webhook_message = data.webhook_message;
  if (data.audio_video_file !== undefined)
    messageBody.audio_video_file = data.audio_video_file;
  if (data.attachments !== undefined)
    messageBody.attachments = data.attachments;
  if (data.delete_attachments !== undefined)
    messageBody.delete_attachments = data.delete_attachments;
  if (data.delete_attachments_after)
    messageBody.delete_attachments_after = data.delete_attachments_after;
  if (data.support_message !== undefined) {
    messageBody.support_message = data.support_message;
  }
  if (data.user_data !== undefined) {
    messageBody.user_data = data.user_data;
  }
  if (data.bot_sender_id !== undefined) {
    messageBody.bot_sender_id = data.bot_sender_id;
  }
  if (data.reminded_by !== undefined) {
    messageBody.reminded_by = data.reminded_by;
  }
  if (data.reminder_of !== undefined) {
    messageBody.reminder_of = data.reminder_of;
  }
  if (data.reminder_title !== undefined) {
    messageBody.reminder_title = data.reminder_title;
  }
  if (data.reminder_to !== undefined) {
    messageBody.reminder_to = data.reminder_to;
  }
  if (data.reminder_id !== undefined) {
    messageBody.reminder_id = data.reminder_id;
  }
  if (data.user_add_remove !== undefined) {
    messageBody.user_add_remove = data.user_add_remove;
  }
  if (data.announcement !== undefined) {
    messageBody.announcement = data.announcement;
  }
  if (data.announcement_user !== undefined) {
    messageBody.announcement_user = data.announcement_user;
  }
  if (data.child_read !== undefined) {
    messageBody.child_read = data.child_read;
  }
  if (data.plugin_data !== undefined) {
    messageBody.plugin_data = data.plugin_data;
  }
  if (data.visible_to !== undefined) {
    messageBody.visible_to = data.visible_to;
  }
  if (data.poll_id) {
    messageBody.poll_id = data.poll_id;
  }
  if (data.poll_data) {
    messageBody.poll_data = data.poll_data;
  }
  if (data.emoji !== undefined) {
    messageBody.emoji = data.emoji;
  }
  if (data.zoom !== undefined) {
    Vue.set(messageBody, "zoom", data.zoom);
    //messageBody.zoom = data.zoom;
  }
  return messageBody;
};
var messageSocket = {
  state: {
    isAnyNewMessage: false,
    typistDetail: {},
    typingStatus: false,
    newMessages: {},
  },
  getters: {
    getTypistDetail: (state) => state.typistDetail,
    getIsAnyNewMessage: (state) => state.isAnyNewMessage,
    getTypingStatus: (state) => state.typingStatus,

    /**
     * This getter returns company id, team id and channel object for finding relevant data.
     * Used as a helper to find details whenever a message emit recieved
     *
     * @param {Object} data The data object find all id's
     * @return {Object} company id, team id and channel object
     */

    getMessagesDetail: (state, getters, rootState) => (data) => {
      let teamIndex = false;
      let channel = false;
      let companies = rootState.storeData.companies;
      try {
        //iterate through all companies to find company index
        let companyIndex = companies.findIndex(
          (company) => company._id == data.company_id
        );
        //if channel type is not direct and group
        if (data.type != "direct" && data.type != "group") {
          //iterate through all the teams of company
          teamIndex = companies[companyIndex].teams.findIndex(
            (team) => team._id == data.team_id
          );
          //iterate through all the channels of that company and return the required channel
          channel = companies[companyIndex].channels.find(
            (channel) =>
              ((data.type != "support" &&
                channel.team_id ==
                companies[companyIndex].teams[teamIndex]._id) ||
                data.type == "support") &&
              channel._id == data.channel_id
          );
        } else {
          //find direct channel
          channel = companies[companyIndex].direct.find(
            (channel) => channel._id == data.channel_id
          );
        }
        //return obejct
        return {
          companyIndex,
          teamIndex,
          channel,
        };
      } catch (error) {
        //on error return channel again
        return {
          channel: channel,
        };
      }
    },
  },
  actions: {
    /**
     * This action listen to the socket (replySendAfterMessage), it adds new schedule reply message in the reply messages.
     * (1): first it find company index, team index and channel index from channel_id, team_id and company_id
     * (2): then it checks if receiver has not opened the thread reply of same parent message as new replies parent message
     *      (a): call setReplyCount (mutation),
     * (3): then finds the index of replied message from channel i.e. (channelMessageIndex).
     * (4): if receiver (not sender) have already opened the message
     *      (a): find the index of new reply if it has already added in the channel.
     *      (b): the call action addReplied_id
     *      (c): then this action commit setReplyMessageThread to all message in thread_child_messages (unread replies).
     * (5): then this action commit new_message_count
     *
     * @param {Object} socketData the data recieved from socket
     * @return {void}
     */

    async socket_replySendAfterMessage(context, socketData) {
      //callback handler check whether emit data have ack then send ack and restructure data back
      let data = await context.dispatch("callBackHandler", socketData);

      //find all the details of message including company id, team id and channel object
      let messageDetail = context.getters.getMessagesDetail(data);
      //if channel found
      if (messageDetail.channel) {
        if (
          context.rootGetters.loggedInUserDetails._id != data.data.sender_id &&
          context.rootGetters.getParentReplyId != data.data.parent._id
        ) {
          //pass data to mutation
          context.commit("setReplyCount", {
            messageDetail,
            data: data.data,
          });
        }

        if (
          context.rootGetters.getParentReplyId != null &&
          context.rootGetters.getParentReplyId == data.data.parent._id
        ) {
          let index = await context.rootGetters.getReplyedMessages.child.findIndex(
            (item) => {
              return item._id == data.data._id || item._id == data.data.temp_id;
            }
          );
          context.dispatch("addReplied_id", data.data._id);
          context.commit("setReplyMessageThread", {
            messages: context.rootGetters.getReplyedMessages.child,
            data: data.data,
            index,
            messageDetail,
          });
        }
      }
    },
    /**
     * this action listen to the socket (replyUpdateMessage), it updates the reply message in the replied messages.
     * (1): first it find company index, team index and channel index from channel_id, team_id and company_id
     * (2): then finds the message from loded replied
     * (3): if receiver (not sender) have already opened the message
     *      (a): then this action commit updateMessageDetails(mutation) to update the new changes in reply message.
     *
     * @param {Object} socketData the data recieved from socket
     * @return {void}
     */
    async socket_replyUpdateMessage(context, socketData) {
      //callback handler check whether emit data have ack then send ack and restructure data back
      let data = await context.dispatch("callBackHandler", socketData);

      //find all the details of message including company id, team id and channel object
      let messageDetail = context.getters.getMessagesDetail(data);

      //find the replied message
      let message = context.rootGetters.getReplyedMessages.child.find(
        (item) => {
          return item._id == data.data._id;
        }
      );
      //if channel is found
      if (messageDetail.channel) {
        if (
          context.rootGetters.getParentReplyId != null &&
          context.rootGetters.getParentReplyId == data.data.parent._id
        ) {
          context.commit("updateMessageDetails", {
            storeData: message,
            data: data.data,
            messages: context.rootGetters.getReplyedMessages.child,
            isReply: true,
          });
        }
      }
    },
    /**
     * this action listen to the socket (socket_newReplyMessage), it adds new new reply message in the reply messages.
     * (1): first it find company index, team index and channel index from channel_id, team_id and company_id
     * (2): then it checks if receiver has opened the thread reply of same parent message as new replies parent message
     *      (a): call replyRead (action), to remove message from receiver's account opened on other device.
     * (3): this action calls the mutation setNotification to set the in app notification.
     * (4): then it checks if receiver has not opened the thread reply of same parent message as new replies parent message.
     *       (a): call replyRead (setReplyCount),
     * (5): then finds the index of replied message from channel i.e. (channelMessageIndex).
     * (6): if receiver (not sender) have already opened the message
     *      (a): find the index of new reply if it has already added in the channel.
     *      (b): the call action addReplied_id
     *      (c): then this action commit setReplyMessageThread to all message in thread_child_messages (unread replies).
     * (7): then this action commit setReplyMessageThread
     *
     * @param {Object} socketData the data recieved from socket
     * @return {void}
     */
    async socket_newReplyMessage(context, socketData) {
      //callback handler check whether emit data have ack then send ack and restructure data back
      let data = await context.dispatch("callBackHandler", socketData);

      clearTimeout(stopTimer);
      context.commit("setStopTypingStatus");

      //find all the details of message including company id, team id and channel object
      let messageDetail = context.getters.getMessagesDetail(data);
      let company_id = context.rootGetters.getSelectedCompany._id;
      //find company user
      let companyUser = context.rootGetters.getUserFromCompanyUsers(
        company_id,
        context.rootGetters.loggedInUserDetails._id
      );
      //pass data to mutation for notification
      context.commit("setNotification", {
        loginUserDetail: context.rootGetters.loggedInUserDetails,
        companyUser: companyUser,
        selectedCompanyId: context.rootGetters.getSelectedCompany._id,
        data: messageBody(data.data),
        channel: messageDetail.channel,
        company:
          context.rootState.storeData.companies[messageDetail.companyIndex],
        team:
          context.rootState.storeData.companies[messageDetail.companyIndex]
            .teams[messageDetail.teamIndex],
        teamId: data.team_id,
        selectedTeamId: context.rootGetters.getSelectedTeam
          ? context.rootGetters.getSelectedTeam._id
          : null,
        selectedChannel: context.rootState.selectedChannel,
      });

      //if channel found
      if (messageDetail.channel) {
        context.dispatch("updateReplyParent", {
          channel: messageDetail.channel,
          childMsg: data.data,
        });
        if (
          context.rootGetters.loggedInUserDetails._id != data.data.sender_id &&
          context.rootGetters.getParentReplyId != data.data.parent._id
        ) {
          //pass data to set reply count
          context.commit("setReplyCount", {
            messageDetail,
            data: data.data,
          });
        } else {
          if (
            context.rootGetters.getReplyedMessages.child &&
            context.rootGetters.getParentReplyId == data.data.parent._id
          ) {
            let index = await context.rootGetters.getReplyedMessages.child.findIndex(
              (item) => {
                return (
                  item._id == data.data._id || item._id == data.data.temp_id
                );
              }
            );
            context.commit("setReplyMessageThread", {
              messages: context.rootGetters.getReplyedMessages.child,
              data: data.data,
              index,
              messageDetail,
            });
            if (index != undefined && index < 0) {
              if (
                context.rootGetters.loggedInUserDetails._id !=
                data.data.sender_id &&
                context.rootGetters.getParentReplyId != null &&
                context.rootGetters.getParentReplyId == data.data.parent._id &&
                "channelId" in router.currentRoute.params
              ) {
                this.dispatch("singleReplyRead", {
                  message_id: data.data._id,
                  channel_id: messageDetail.channel._id,
                  invited: messageDetail.channel.invited,
                  secretKey: messageDetail.channel.secret_key,
                });
              }
            }
          }
        }
      }
    },
    /**
     * this action is responsible for appending new message from scoket in to messages in store.
     * (1): first it find company index, team index and channel index from channel id
     * (2): then this action commit the new message.
     * @param {Object} socketData the data recieved from socket
     * @return {void}
     */
    async socket_newMessage(context, socketData) {
      //callback handler check whether emit data have ack then send ack and restructure data back
      let data = await context.dispatch("callBackHandler", socketData);

      clearTimeout(stopTimer);
      //if reminder message emit
      if ("reminder_of" in data.data) {
        let reminderMessages = context.rootGetters.getReminderMessages;
        let index = reminderMessages.findIndex(
          (reminderMessage) => reminderMessage._id == data.data.reminder_id
        );
        if (index > -1) {
          context.commit("markCompleteReminderMessage", {
            reminderMessages,
            index: index,
          });
        }
      }
      context.commit("setStopTypingStatus");
      //getting messages from store on the basise ids coming from socket of new message.
      let messageDetail = context.getters.getMessagesDetail(data);
      let messageIndex;
      let company_id = context.rootGetters.getSelectedCompany._id;
      if (messageDetail.channel) {
        let body = messageBody(data.data);
        if (
          context.rootGetters.getMessagesByChannelId(
            messageDetail.channel._id
          ) == undefined
        ) {
          context.commit("setAddChannelObj", data);
          if (context.state.newMessages[data.channel_id]) {
            if (
              context.state.newMessages[data.channel_id].includes(
                data.data._id
              ) == false
            ) {
              //passing data in newMessage mutation.
              context.commit("addMessageCount", {
                loginUserID: context.rootGetters.loggedInUserDetails._id,
                // messageIndex,
                data: body,
                channel: messageDetail.channel,
                company:
                  context.rootState.storeData.companies[
                  messageDetail.companyIndex
                  ],
                team:
                  context.rootState.storeData.companies[
                    messageDetail.companyIndex
                  ].teams[messageDetail.teamIndex],
                selectedChannel: context.rootGetters.getSelectedChannel,
                msgsSearchActive: context.rootState.messageStore.jumpMessage,
                tempMessage: context.state.newMessages[
                  data.channel_id
                ].includes(data.data._id),
              });
            }
          }
          context.commit("setNewTempMessages", data);
        } else {
          let tempMessage;
          if (
            context.rootGetters.getMessagesByChannelId(
              messageDetail.channel._id
            )
          ) {
            messageIndex = await context.rootGetters
              .getMessagesByChannelId(messageDetail.channel._id)
              .findIndex((item) => {
                return (
                  item._id == data.data._id || item._id == data.data.temp_id
                );
              });
          }
          context.commit("setAddChannelObj", data);
          if (
            context.state.newMessages[data.channel_id] &&
            context.state.newMessages[data.channel_id].includes(
              data.data._id
            ) == false
          ) {
            context.commit("newMessage", {
              storeData: context.rootGetters.getMessagesByChannelId(
                messageDetail.channel._id
              ),
              messageIndex,
              data: body,
              channel: messageDetail.channel,
              selectedChannel: context.rootState.selectedChannel,
            });
            //passing data in setNotification for in app notification defined in inAppNotifications.js
            if (messageIndex == undefined || messageIndex == -1) {
              if (
                data.data.user_add_remove != null &&
                data.data.user_add_remove == false
              ) {
                company_id = context.rootGetters.getSelectedCompany._id;
              }
              //passing data in newMessage mutation.
              context.commit("addMessageCount", {
                loginUserID: context.rootGetters.loggedInUserDetails._id,
                messageIndex,
                data: body,
                channel: messageDetail.channel,
                company:
                  context.rootState.storeData.companies[
                  messageDetail.companyIndex
                  ],
                team:
                  context.rootState.storeData.companies[
                    messageDetail.companyIndex
                  ].teams[messageDetail.teamIndex],
                selectedChannel: context.rootGetters.getSelectedChannel,
                msgsSearchActive: context.rootState.messageStore.jumpMessage,
              });
            }
            if (messageIndex == -1) {
              //for single message read
              if (
                data.data.sender_id !=
                context.rootGetters.loggedInUserDetails._id &&
                context.rootState.selectedChannel &&
                data.channel_id == context.rootState.selectedChannel._id &&
                data.data.send_after == undefined &&
                !document.hidden == true &&
                document.hasFocus() &&
                "channelId" in router.currentRoute.params
              ) {
                context.dispatch("singleMessageRead", {
                  ...data,
                  invited: messageDetail.channel.invited,
                  secretKey: messageDetail.channel.secret_key,
                });
              }
            }
          }
          context.commit("setNewTempMessages", data);
        }
        if (
          (context.state.newMessages[data.channel_id] &&
            context.state.newMessages[data.channel_id].includes(
              data.data._id
            ) == false) ||
          messageIndex == undefined ||
          messageIndex == -1
        ) {
          let companyUser = context.rootGetters.getUserFromCompanyUsers(
            company_id,
            context.rootGetters.loggedInUserDetails._id
          );
          if (
            data.data.user_add_remove != null &&
            data.data.user_add_remove == false
          ) {
            context.commit("setNotification", {
              loginUserDetail: context.rootGetters.loggedInUserDetails,
              companyUser: companyUser,
              selectedCompanyId: context.rootGetters.getSelectedCompany._id,
              data: body,
              channel: messageDetail.channel,
              company:
                context.rootState.storeData.companies[
                messageDetail.companyIndex
                ],
              team:
                context.rootState.storeData.companies[
                  messageDetail.companyIndex
                ].teams[messageDetail.teamIndex],
              teamId: data.team_id,
              selectedTeamId: context.rootGetters.getSelectedTeam
                ? context.rootGetters.getSelectedTeam._id
                : null,
              selectedChannel: context.rootState.selectedChannel,
            });
          }
        }
      }
    },
    /**
     * This action listen to the socket and call mutation to change the updated data.
     * @param {Object} socketData the data recieved from socket
     * @return {void}
     */
    async socket_updateMessage(context, socketData) {
      //callback handler check whether emit data have ack then send ack and restructure data back
      let data = await context.dispatch("callBackHandler", socketData);

      //getting channel detail from getChannelDetails (in home)
      //getting messages from store on the basis of ids coming from socket of new message.
      let messageDetail = context.getters.getMessagesDetail(data);
      let messages = context.rootGetters.getMessagesByChannelId(
        messageDetail.channel._id
      );
      if (messageDetail.channel && messages) {
        let message = await messages.find((item) => {
          return item._id == data.data._id;
        });
        // pass data to mutation to change updated data.
        context.commit("updateMessageDetails", {
          messages,
          storeData: message,
          data: data.data,
        });
      }
    },
    /**
     * This action listen to the socket and call mutation whenever someone is typing in the same channel.
     * @param {Object} data the data recieved from socket
     * @return {void}
     */
    socket_someoneIsTyping(context, data) {
      let channel = context.rootState.selectedChannel;
      clearTimeout(stopTimer);
      if (channel != undefined && channel != null && "_id" in channel) {
        let loginUserId = context.rootGetters.loggedInUserDetails._id;
        let selectedChannelId = context.rootState.selectedChannel
          ? context.rootState.selectedChannel._id
          : null;
        context.commit("settypistDetail", {
          data,
          loginUserId,
          selectedChannelId,
        });
        stopTimer = setTimeout(function () {
          context.commit("setStopTypingStatus");
        }, 4000);
      }
    },
    /**
     * This action listen to the socket reaction added and triggered whenever a reaction is added on message
     * @param {Object} data the data recieved from socket
     * @return {void}
     */
    socket_reactionAdded(context, data) {
      let messageDetail = context.getters.getMessagesDetail(data);
      let messageIndex;
      if (data.is_reply == false) {
        if (
          messageDetail.channel &&
          context.rootGetters.getMessagesByChannelId(messageDetail.channel._id)
        ) {
          messageIndex = context.rootGetters
            .getMessagesByChannelId(messageDetail.channel._id)
            .findIndex((message) => {
              return message._id == data.message_id;
            });
        }
      } else {
        messageIndex = -1;
        //if thread drawer is open
        if (context.rootGetters.getReplyedMessages?.child) {
          messageIndex = context.rootGetters.getReplyedMessages.child.findIndex(
            (message) => {
              return message._id == data.message_id;
            }
          );
        }
      }
      context.commit("reactionAdded", {
        currentMessages:
          data.is_reply == false
            ? context.rootGetters.getMessagesByChannelId(
              messageDetail.channel._id
            )
            : context.rootGetters.getReplyedMessages.child,
        messageIndex,
        reaction: data.reaction,
        user_id: data.user_id,
      });
    },

    /**
     * This action listen to the socket reaction removed and triggered whenever a reaction is removed from message
     * @param {Object} data the data recieved from socket
     * @return {void}
     */
    socket_reactionRemoved(context, data) {
      let messageDetail = context.getters.getMessagesDetail(data);
      let messageIndex;
      if (data.is_reply == false) {
        if (
          messageDetail.channel &&
          context.rootGetters.getMessagesByChannelId(messageDetail.channel._id)
        ) {
          messageIndex = context.rootGetters
            .getMessagesByChannelId(messageDetail.channel._id)
            .findIndex((message) => {
              return message._id == data.message_id;
            });
        }
      } else {
        messageIndex = context.rootGetters.getReplyedMessages.child.findIndex(
          (message) => {
            return message._id == data.message_id;
          }
        );
      }
      context.commit("reactionRemoved", {
        currentMessages:
          data.is_reply == false
            ? context.rootGetters.getMessagesByChannelId(
              messageDetail.channel._id
            )
            : context.rootGetters.getReplyedMessages.child,
        messageIndex,
        reaction: data.reaction,
        user_id: data.user_id,
      });
    },
    /**
     * this action responsible for emiting data to node server to set typing status of typing.
     * args: (context, data)
     */
    typing(context, data) {
      this._vm.$socket.client.emit("typing", data);
    },
    /**
     * this action responsible for calling setIsAnyNewMessage mutation.
     * args: (context, data)
     */
    resetIsNewMessage({ commit }) {
      commit("setIsAnyNewMessage", false);
    },
    /**
     * this action responsible for calling api and then emit to socket for single message read
     * args: (context, data)
     */
    singleMessageRead(context, data) {
      var done = () => { };
      messageAPI.updateMessage(
        data.data._id,
        data.channel_id,
        undefined, //undefined
        undefined, //undefined
        undefined, //undefined
        undefined, //undefined
        true, //single_read
        undefined,
        undefined,
        data.invited,
        data.secretKey,
        undefined,
        done
      );
    },
    /**
     * this action responsible for calling api for making reply read.
     * args: (context, data)
     */
    singleReplyRead(context, data) {
      var done = () => { };
      messageAPI.sceenReply(
        data.channel_id,
        data.message_id,
        data.invited,
        data.secretKey,
        done
      );
    },
    socket_messagePermanentlyDeleted(context, data) {
      let previousMessages = context.rootGetters.getCurrentMessages;

      //get message index from store Data
      let index = context.rootGetters.getSendMessageIndex(
        data.message_id,
        null,
        previousMessages
      );
      if (index > -1) {
        context.commit("completeMessageRemindMutation", {
          currentMessages: previousMessages,
          index,
        });
      }
    },
    socket_pollStatusUpdated(context, data) {
      if (data.channel_id) {
        let messages = context.rootGetters.getMessagesByChannelId(
          data.channel_id
        );
        if (messages) {
          let poll = messages.find((message) => {
            return message.poll_id == data.poll_id;
          }).poll_data;
          if (poll) {
            context.commit("pollStatusUpdated", {
              poll,
              poll_status: data.poll_status,
            });
          }
        }
      }
      if (data.poll_data) {
        let messages = context.rootGetters.getMessagesByChannelId(
          data.poll_data.channel_id
        );
        if (messages) {
          let poll = messages.find((message) => {
            return message.poll_id == data.poll_data.poll_id;
          }).poll_data;
          if (poll) {
            context.commit("pollStatusUpdated", {
              poll,
              poll_status: data.poll_data.poll_status,
            });
          }
        }
      }
    },

    async callBackHandler(context, socketData) {
      return new Promise((resolve) => {
        if (Array.isArray(socketData)) {
          let ack = socketData[1];
          if (ack) {
            let token = JSON.parse(localStorage.getItem("token"));
            ack(token, socketData[0].data._id);
          }
          resolve(socketData[0]);
        } else {
          resolve(socketData);
        }
      });
    },
    /**
     * Find parent message of child then add child id in replied_ids of parent.
     * args: (context, payload)
     */
    async updateReplyParent(context, payload) {
      let channelMessages = await context.rootGetters.getMessagesByChannelId(
        payload.channel._id
      );
      if (channelMessages) {
        let msgIndex = channelMessages.findIndex(
          (msg) => msg._id == payload.childMsg.parent._id
        );
        if (msgIndex > -1) {
          context.commit("UPDATE_REPLY_PARENT", {
            channelMessages,
            msgIndex,
            childMsg: payload.childMsg,
          });
        }
      }
    },
  },
  mutations: {
    markCompleteReminderMessage(state, payload) {
      Vue.set(payload.reminderMessages[payload.index], "completed", true);
    },
    completeMessageRemindMutation: (state, payload) => {
      payload.currentMessages.splice(payload.index, 1);
    },
    /**
     * this mutation is responsible for update any change that is received in update socket.
     * args: (state, payload)
     */
    updateMessageDetails(state, payload) {
      if (payload.storeData) {
        if (
          payload.data.replied_ids.length !=
          payload.storeData.replied_ids.length
        ) {
          payload.storeData.replied_ids = payload.data.replied_ids;
        }
        if (payload.data.message !== undefined) {
          payload.storeData.message = payload.data.message;
        }
        if (payload.data.is_edited !== undefined) {
          payload.storeData.is_edited = payload.data.is_edited;
        }
        if (payload.data.delete_after !== undefined) {
          payload.storeData.delete_after = payload.data.delete_after;
        }
        if (payload.data.created_at !== undefined) {
          payload.storeData.created_at = payload.data.created_at;
        }
        if (payload.data.updated_at !== undefined) {
          payload.storeData.updated_at = payload.data.updated_at;
        }
        if (payload.data.deleted_at !== undefined) {
          payload.storeData.deleted_at = payload.data.deleted_at;
        }
        if (payload.data.delete_after !== undefined) {
          payload.storeData.delete_after = payload.data.delete_after;
        }
        if (payload.data.delete_attachments_after !== undefined) {
          payload.storeData.delete_attachments_after =
            payload.data.delete_attachments_after;
        }
        if (payload.data.deleted_at != "undefined") {
          payload.storeData.pinned_by = [];
          payload.storeData.pinned_by = payload.data.pinned_by;
        }
        if (
          payload.data.replied_sender_id != "undefined" &&
          payload.data.replied_created_at != "undefined"
        ) {
          payload.storeData.replied_sender_id = payload.data.replied_sender_id;
          payload.storeData.replied_created_at =
            payload.data.replied_created_at;
        }
        payload.storeData.reminded_by = payload.data.reminded_by
          ? payload.data.reminded_by
          : [];
        //update send after
        if ("send_after" in payload.data) {
          payload.storeData.send_after = payload.data.send_after;
        }
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.

        if (payload.isReply) {
          payload.messages.sort(function (a, b) {
            return (
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
            );
          });
        } else {
          payload.messages.sort(function (a, b) {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
        }
      }
    },
    /**
     * this mutation is responsible for adding new message from scoket in sroreData
     * args: (state, payload)
     */
    newMessage(state, payload) {
      //if message in found replace message with new message.
      if (payload.messageIndex >= 0) {
        payload.storeData.splice(payload.messageIndex, 1, payload.data);
      } else if (payload.messageIndex == -1) {
        payload.storeData.unshift(payload.data);
        if (
          (!payload.selectedChannel ||
            (payload.channel &&
              payload.selectedChannel &&
              payload.channel._id != payload.selectedChannel._id)) &&
          payload.storeData.length > 20
        ) {
          payload.storeData.pop();
        }
        if (
          payload.channel &&
          payload.selectedChannel &&
          payload.channel._id == payload.selectedChannel._id
        ) {
          state.isAnyNewMessage = true;
        }
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        payload.storeData.sort(function (a, b) {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
      }
    },
    /**
     * this mutation is responsible for setting isAnyNewMessage state with the data provided from action.
     * args :(state, payload)
     */
    setIsAnyNewMessage(state, payload) {
      state.isAnyNewMessage = payload;
    },
    /**
     * this mutation is responsible for setting data of typist who is typing on the other side.
     * args :(state, payload)
     */
    settypistDetail(state, payload) {
      if (
        payload.data.loginUserId != payload.loginUserId &&
        payload.data.channel_id == payload.selectedChannelId
      ) {
        state.typingStatus = true;
        state.typistDetail = payload.data;
      }
    },
    /**
     * this mutation is responsible for removing data of typist who's data is set.
     * args :(state, payload)
     */
    setStopTyping(state, payload) {
      if (
        payload.data.loginUserId != payload.loginUserId &&
        payload.data.channel_id == payload.selectedChannelId
      ) {
        state.typingStatus = false;
        state.typistDetail = {};
      }
    },
    /**
     * this mutation is responsible for setting typingStatus to false in state.
     * args: (state, payload)
     */
    setStopTypingStatus(state) {
      state.typingStatus = false;
    },
    /**
     * this mutation is responsible adding new reply message in to the thread_child_messages (unsceen messages).
     * args: (state, payload)
     */
    setReplyCount(state, payload) {
      let threadChildIndex = payload.messageDetail.channel.thread_child_messages.findIndex(
        (item) => {
          return item.parent._id == payload.data.parent._id;
        }
      );
      if (threadChildIndex == -1) {
        payload.messageDetail.channel.thread_child_messages.push(payload.data);
      } else {
        payload.messageDetail.channel.thread_child_messages[threadChildIndex] =
          payload.data;
      }
      payload.messageDetail.channel.last_message_at = payload.data.created_at;
    },
    /**
     * this mutation is responsible adding new reply message repliedMessage (if user have opened that message).
     * args: (state, payload)
     */

    setReplyMessageThread(state, payload) {
      let tempMessage = {
        ...payload.data,
      };
      delete tempMessage.parent;

      if (payload.index < 0) {
        payload.messageDetail.channel.last_message_at = tempMessage.created_at;
        payload.messages.push(tempMessage);
      } else {
        if (payload.messages[payload.index]._id)
          payload.messages[payload.index]._id = tempMessage._id;
        if (payload.messages[payload.index].send_after)
          payload.messages[payload.index].send_after = tempMessage.send_after;
        if (
          payload.messages[payload.index].created_at &&
          tempMessage.created_at
        )
          payload.messages[payload.index].created_at = tempMessage.created_at;
      }
      payload.messages.sort(function (a, b) {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
    },
    reactionAdded(state, payload) {
      if (payload.messageIndex > -1) {
        let reaction = payload.currentMessages[
          payload.messageIndex
        ].reactions.find((item) => {
          return item.reaction.aliases[0] == payload.reaction.aliases[0];
        });

        if (reaction) {
          let userIndex = reaction.user_ids.findIndex(
            (id) => payload.user_id == id
          );
          if (userIndex == -1) {
            reaction.user_ids.push(payload.user_id);
          }
        } else {
          let user_ids = [];
          user_ids.push(payload.user_id);
          payload.currentMessages[payload.messageIndex].reactions.push({
            reaction: payload.reaction,
            user_ids,
          });
        }
      }
    },
    reactionRemoved(state, payload) {
      if (payload.messageIndex > -1) {
        let reaction = payload.currentMessages[
          payload.messageIndex
        ].reactions.find((item) => {
          return item.reaction.aliases[0] == payload.reaction.aliases[0];
        });
        if (reaction) {
          let userIndex = reaction.user_ids.findIndex(
            (id) => payload.user_id == id
          );
          if (userIndex > -1) {
            if (reaction.user_ids.length == 1) {
              let reactionIndex = payload.currentMessages[
                payload.messageIndex
              ].reactions.findIndex((item) => {
                return item.reaction.aliases[0] == payload.reaction.aliases[0];
              });
              payload.currentMessages[payload.messageIndex].reactions.splice(
                reactionIndex,
                1
              );
            } else {
              reaction.user_ids.splice(userIndex, 1);
            }
          }
        }
      }
    },
    addMessageCount(state, payload) {
      if (payload.messageIndex == -1 || payload.tempMessage == false) {
        //set last message at of channel to sort according to latest message
        payload.channel.last_message_at = payload.data.created_at;
        //new message count
        //if user in search message history then show new message count
        if (
          !payload.channel.muted &&
          payload.data.user_add_remove != null &&
          payload.data.user_add_remove != true &&
          (!payload.selectedChannel ||
            (payload.selectedChannel &&
              payload.channel._id == payload.selectedChannel._id &&
              payload.msgsSearchActive != null) ||
            !payload.selectedChannel ||
            (payload.selectedChannel &&
              payload.channel._id != payload.selectedChannel._id) ||
            document.hidden == true ||
            !document.hasFocus())
        ) {
          if (
            payload.data.sender_id != payload.loginUserID &&
            !payload.data.send_after
          ) {
            if (payload.channel.joined) {
              payload.channel.new_message_count += 1;
            }
          }
        }
      }
    },
    setAddChannelObj(state, paylode) {
      if (state.newMessages[paylode.channel_id] == undefined) {
        Vue.set(state.newMessages, paylode.channel_id, []);
      }
    },
    setNewTempMessages(state, paylode) {
      if (
        state.newMessages[paylode.channel_id].includes(paylode.data._id) ==
        false
      ) {
        state.newMessages[paylode.channel_id].push(paylode.data._id);
      }
    },
    removeNewMessages(state, payload) {
      if (state.newMessages[payload.channel_id])
        delete state.newMessages[payload.channel_id];
    },
    pollStatusUpdated(state, payload) {
      payload.poll.poll_status = payload.poll_status;
      payload.poll_status == "closed"
        ? (payload.poll.end_time = "manual")
        : payload.poll_status == "open"
          ? (payload.poll.start_time = "now")
          : "";
    },
    /**
     * if parent dose not include new child id then add and upate other fields as well
     * args: (state, payload)
     */
    UPDATE_REPLY_PARENT(state, payload) {
      payload?.childMsg?.parent?.replied_ids?.forEach((replyId) => {
        if (
          !payload.channelMessages[payload.msgIndex]?.replied_ids?.includes(
            replyId
          )
        ) {
          // check to remove or not the instant temp id from the replied ids array
          let tempRepliedIdIndex = payload.channelMessages[
            payload.msgIndex
          ]?.replied_ids?.indexOf(payload?.childMsg.temp_id);
          if (tempRepliedIdIndex >= 0) {
            payload.channelMessages[payload.msgIndex]?.replied_ids?.splice(
              tempRepliedIdIndex,
              1
            );
          }
          payload.channelMessages[payload.msgIndex].replied_ids.push(replyId);
        }
      });
      if (
        !payload.channelMessages[payload.msgIndex]?.replied_ids?.includes(
          payload.childMsg._id
        )
      ) {
        payload.channelMessages[payload.msgIndex].replied_sender_id =
          payload.childMsg.sender_id;
        payload.channelMessages[payload.msgIndex].replied_created_at =
          payload.childMsg.parent.replied_created_at;
      }
    },
  },
};
export default messageSocket;
