<template>
  <v-app class="h-100 hide-x-scroll">
    <AudioPlayerInHeader v-if="showAudio" :Urlpath="getURLandTime" />
    <router-view class="h-100"></router-view>
  </v-app>
</template>

<script>
import AudioPlayerInHeader from "@/components/UserDashboard/Channels/AudioPlayerInHeader";
import { eventBus } from "@/main";
import { mapGetters } from "vuex";
import requestQueue from "@/service/requestQueue";
import moment from "moment-timezone";
import broadcastChannel from "@/service/broadcastChannel.js";
export default {
  name: "App",
  components: {
    AudioPlayerInHeader,
  },
  data() {
    return {
      isPageReload: false,
    };
  },
  created() {
    this.$debug.log(process.env.VUE_APP_BASE_URL);
    if (localStorage.getItem("token") != null) {
      this.isPageReload = true;
    }
    window.refreshMobileDevice = this.refreshMobileDevice;
    // Listen for messages from the broadcast channel
    broadcastChannel.addEventListener("message", this.handleMessage);
  },
  computed: {
    ...mapGetters([
      "getRequestQueue",
      "getLoggedInUserDetail",
      "getSelectedCompany",
      "getSelectedTeam",
      "getURLandTime",
      "getSelectedChannel",
    ]),
    // this function will allow render the audio component
    showAudio() {
      return (
        this.getURLandTime.url &&
        this.getURLandTime.channelsIdDetail.selectedChannel._id !==
          this.getSelectedChannel._id
      );
    },
  },

  watch: {
    getRequestQueue(queue) {
      if (this.isPageReload && localStorage.getItem("token") != null) {
        // clear previous saved messages
        this.$store.commit("clearChannelMessage");
        this.isPageReload = false;
        this.$store.dispatch("getUserData").then(async () => {
          await this.$store.dispatch("getMessagesFromAPI");
          if (this.$store.state.queue.length != 0) {
            await requestQueue.startQueue();
          }
        });
      }
    },

    $route: {
      handler: function(to) {
        document.title = to.meta.title || "Whistle It";
        //close all opened drawers on route change
        eventBus.$emit("isReplySetWidth", false);
        eventBus.$emit("isSearchSetWidth", false);
        eventBus.$emit("isPinSetWidth", false);
        eventBus.$emit("isUnsceenSetWidth", false);
        eventBus.$emit("openMessageReminderDrawer", false);
        this.$store.commit("UPDATE_GLOBAL_SEARCH_DRAWER", false);
        this.$store.commit("UPDATE_PROFILE_DRAWER_STATE", false);
        this.$store.commit("UPDATE_HELP_DRAWER_STATE", false);
      },
      immediate: true,
    },
  },
  methods: {
    // reload page in case when user is logout from 1 tab to sync all tabs of same user
    checkLocalStorage() {
      window.addEventListener("storage", (event) => {
        if (event.storageArea != localStorage) return;
        if (event.key == "logout-event") {
          window.location.reload();
        }
        if (event.key == "token" && event.oldValue == null) {
          window.location.reload();
        }
      });
    },
    refreshMobileDevice() {
      return true;
    },
    /**
     * Handles incoming messages from a Broadcast Channel.
     * @param {MessageEvent} event - The event containing the message data.
     */
    handleMessage(event) {
      // Check if the received message is meant to close an already open window.
      if (event.data.type === "closeAlreadyOpenWindow") {
        // Extract the companyId from the received message data.
        const companyIdToClose = event.data.companyId;

        // Check if the current window's URL includes the companyId to be closed.
        if (window?.location?.href?.includes(companyIdToClose)) {
          // Close the current window.
          window.close();
        }
      }
    },
  },
  mounted() {
    console.log('new busssild')
    document.addEventListener(
      "play",
      function(e) {
        var audios = document.getElementsByTagName("audio");
        for (var i = 0, len = audios.length; i < len; i++) {
          if (audios[i] != e.target) {
            audios[i].pause();
            audios[i].currentTime = 0;
          }
        }
      },
      true
    );
    window.ononline = async () => {
      //start queue on internet
      if (localStorage.getItem("token") != null) {
        //update time zone
        this.$store
          .dispatch("updateUserTimezone", { user_timezone: moment.tz.guess() })
          .catch((error) => console.log("error message", error));
        // clear previous saved messages
        this.$store.commit("clearChannelMessage");
        this.$store.dispatch("getUserData").then(async () => {
          await this.$store.dispatch("getMessagesFromAPI");
          await requestQueue.startQueue();
        });
      }
    };
    this.checkLocalStorage();
    //
  },

  /**
   * Vue lifecycle hook which capture errors and send webhooks to slack
   *
   * @param {object} err The error object provided by Vue Instance.
   * @param {Object} vm The current vue instance
   * @param {Object} info The info object provided by Vue
   * @return void
   */
  errorCaptured(err, vm, info) {
    let payload = {
      text: "This is Whistle It webhook.",
      username: "Whistle It",
      icon_url: "https://app.whistleit.io/uploads/images/whistleit.png",
      attachments: [
        {
          author_name: window.location.href,
          color: "danger",
          title: "Error",
          fallback: info,
          pretext: err.message,
          text: err.stack,
          fields: [
            {
              title: "User's Information",
              value: window.navigator.userAgent,
            },
            {
              title: "Error Information",
              value: info,
              short: true,
            },
            {
              title: "Component name",
              value: vm.$options._componentTag,
              short: true,
            },
            {
              title: "User Email",
              value: this.getLoggedInUserDetail._id,
              short: true,
            },
            {
              title: "User Company",
              value: this.getSelectedCompany._id,
              short: true,
            },
            {
              title: "User Team",
              value: this.getSelectedTeam._id,
              short: true,
            },
            {
              title: "App Version",
              value: localStorage.getItem("App_Version"),
              short: true,
            },
          ],
        },
      ],
    };
    if ("userAgentData" in window.navigator) {
      let ua = window.navigator.userAgentData;

      payload.attachments[0].fields.push({
        title: "User Device",
        value: ua.platform,
        short: true,
      });
      payload.attachments[0].fields.push({
        title: "Is Mobile",
        value: ua.mobile + " ",
        short: true,
      });
      payload.attachments[0].fields.push({
        title: "User Browser",
        value: ua.brands[1].brand,
        short: true,
      });
      payload.attachments[0].fields.push({
        title: "User Browser Version",
        value: ua.brands[1].version,
        short: true,
      });
    }
    //dispatch an action to send webhook to slack
    this.$store.dispatch("frontendWebhooks", payload);
  },
  beforeDestroy() {
    // Clean up the event listener
    broadcastChannel.removeEventListener("message", this.handleMessage);
  },
};
</script>

<style>
/* code {
  white-space: pre-line;
} */

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 100;
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 300;
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 500;
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 700;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-weight: 900;
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-display: swap;
}
html {
  height: -webkit-fill-available;
  -webkit-text-size-adjust: none;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: white !important;
}

html,
body * {
  font-family: "Roboto";
}
.hide-x-scroll {
  overflow-x: hidden !important;
}
</style>
