import companyAPI from "@/api/companyAPI.js";
import debug from "@/console";
import router from "@/router";
import userAPI from "@/api/userAPI.js";
import axios from "axios";
import teamAPI from "@/api/teamAPI.js";

const state = {
  permissions: null,
  interval: 60,
  isOpenProfileDrawer: false,
  userProfileData: {},
  openHelpDrawer: false,
  openMessageReminderDrawer: false,
  openReplyDrawer: false,
};
const getters = {
  //find loggedIn user info
  getLoggedInUserInfo: (state, getters, rootState, rootGetters) =>
    rootGetters.getSelectedCompanyUsers.find(
      (user) => user._id == rootGetters.loggedInUserDetails._id
    ),
  //get permissions
  getPermissions: (state) => state.permissions,
  // Get Login User Info
  getLoginUser: (state, getters, rootState) => {
    return rootState.storeData;
  },

  getUnarchivedUsers: (state, rootGetters) =>
    //find all unarchived users of selected company
    rootGetters.getSelectedCompanyUsers.filter((item) => {
      return item.archived == null;
    }),

  getUserCompanies: (getters, rootGetters) => (userId) => {
    return rootGetters.getCompanies.filter((company) =>
      company.users.some((user) => user._id == userId)
    );
  },
  getUserCustomStatus: (getters, rootGetters) => (userIds) => {
    let result = {
      custom_status: null,
    };
    if (
      userIds.length == 1 &&
      rootGetters.loggedInUserDetails._id == userIds[0]
    ) {
      result = rootGetters.getLoggedInUserDetail;
    } else if (userIds.length <= 2) {
      for (let userId of userIds) {
        rootGetters.getSelectedCompany.users.forEach((user) => {
          if (
            user._id == userId &&
            userId != rootGetters.loggedInUserDetails._id
          ) {
            result = user;
          }
        });
      }
    }
    return result;
  },
  getUserStatus: (getters, rootGetters) => (companyId, userId) => {
    let companyIndex = rootGetters.getCompanies.findIndex(
      (company) => company._id == companyId
    );
    if (companyIndex > -1) {
      let userIndex = rootGetters.getCompanies[companyIndex].users.findIndex(
        (user) => user._id == userId
      );
      if (userIndex > -1) {
        return rootGetters.getCompanies[companyIndex].users[userIndex].status;
      }
    }
  },
  getUserFromCompanyUsers: (getters, rootGetters) => (companyId, userId) => {
    let company;
    company = rootGetters.getCompanies.find(
      (company) => company._id == companyId
    );
    if (company) {
      return company.users.find((user) => user._id == userId);
    }
  },
  getLoggedInUserFromCompanyUsers: (getters, rootGetters) => {
    return rootGetters.getSelectedCompany.users.find(
      (user) => user._id == rootGetters.getLoggedInUserDetail._id
    );
  },
  getProfileDrawerState: (state) => state.isOpenProfileDrawer,
  getUserProfileData: (state) => state.userProfileData,
  getHelpDrawerState: (state) => state.openHelpDrawer,
  getMessageReminderDrawerState: (state) => state.openMessageReminderDrawer,
  getReplyDrawerState: (state) => state.openReplyDrawer,
};
const actions = {
  fetchUsers: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      userAPI.fetchUsers(body, done);
    });
  },
  updateCustomStatus(context, payload) {
    userAPI.updateCustomStatus(payload.requestBody, (response) => {
      if (response.status == 200) {
        payload.companies = context.rootGetters.getUserCompanies(
          payload.userId
        );
        context.commit("UPDATE_USER_DESIGNATION", payload);
        context.commit("UPDATE_CUSTOM_STATUS_EXPIRATION", payload);
      }
    });
  },

  updateUserStatus(context, requestBody) {
    userAPI.updateUserStatus(requestBody.status, (response) => {
      if (response.status == 200) {
        context.commit("UPDATE_USER_STATUS", requestBody);
      }
    });
  },
  //Create User
  // This Action is Dispatched From AddUsersModal File
  // Data to send { CompanyID, Array of Emails[], TeamID in which users are added }
  createNewUser: (context, body) => {
    return new Promise((resolve) => {
      context.commit("createNewMember", {
        users: body.usersArray,
        getter: context.rootGetters.getSelectedCompany.users,
      });
      var done = (response) => {
        if (response.status == 200) {
          context.commit("createNewMemberResponse", {
            new_users: response.data.users,
            company_users: context.rootGetters.getSelectedCompany.users,
            team_ids: body.teams,
            teams: context.rootGetters.getSelectedCompany.teams,
          });
          //data for socket function
          let companyData = context.rootGetters.getSelectedCompany;
          //this script use for socket,it itrates through each team id and dispatch function to team socktes file
          //to add members in teams

          body.teams.forEach((item) => {
            let extractedUserIds = response.data.users.map((item) => item._id);
            //push user ids in defualt channel of team
            let index = companyData.channels.findIndex((channel) => {
              return channel.default == true && channel.team_id == item;
            });
            if (index > -1) {
              companyData.channels[index].users.push(...extractedUserIds);
            }
          });
          resolve(response);
        }
      };
      companyAPI.createUser(body.companyId, body.emails, body.teams, done);
    });
  },

  // Archive User
  // This Action is Dispatched From UsersPannel File
  // Data to send { CompanyID, userID }
  archiveUser: (context, archiveUser) => {
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);
        if (response.status == 200) {
          debug.log("User Archived");
          context.commit("ARCHIVE_USER", {
            users: context.getters.getUnarchivedUsers,
            obj: archiveUser,
            company: context.rootGetters.getSelectedCompany,
          });
        }
      };
      companyAPI.archiveUser(
        archiveUser.userId,
        archiveUser.companyId,
        archiveUser.deleted_at,
        done
      );
    });
  },

  // UnArchive User
  // This Action is Dispatched From UsersPannel File
  // Data to send { CompanyID, userID }
  unArchiveUser: (context, unarchiveUser) => {
    return new Promise((resolve) => {
      var done = (response) => {
        resolve(response);

        if (response.status == 200) {
          context.commit("UNARCHIVE_USER", {
            user_id: unarchiveUser.obj.userId,
            company: context.rootGetters.getSelectedCompany,
            teams: unarchiveUser.teams,
            user: context.rootGetters.getSelectedCompany.users.find(
              (user) => user._id == unarchiveUser.obj.userId
            ),
          });
        }
      };

      companyAPI.unarchiveUser(
        unarchiveUser.obj.userId,
        unarchiveUser.obj.companyId,
        unarchiveUser.obj.deleted_at,
        unarchiveUser.teams,
        done
      );
    });
  },

  // Update Google 2fa
  updateGoogle2fa: (context, data) => {
    let customAxios = axios.create({
      headers: {
        Token: JSON.parse(localStorage.getItem("token")),
      },
    });

    customAxios
      .post(process.env.VUE_APP_BASE_URL + "/updateGoogle2fa", data)
      .then((res) => {
        if (res.status == 200) {
          context.commit("SET_GOOGLE_SECRET", {
            user_email: data.email,
            rootGetters: context.rootGetters,
          });
        }

        debug.log(res);
      });
  },

  // Update Google 2fa
  resetUser2FA: (context, data) => {
    return new Promise((resolve) => {
      userAPI.resetUsers2FA(data, (response) => {
        resolve(response);
      });
    });
  },

  // Forgot Password
  forgotPassword: (context, email) => {
    debug.log(context);

    return new Promise((resolve) => {
      userAPI.forgotPassword(email, (response) => {
        resolve(response);
      });
    });
  },

  //API call for update User Name
  updateUserName: (context, data) => {
    return new Promise((resolve, reject) => {
      userAPI.updateUserName(data.requestBody, (response) => {
        if (response.status == 200) {
          resolve(response);
          context.commit("UPDATE_MSG_INPUT", {
            msgSend: data.requestBody.msg_input_send,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_CLICK_REMINDER", {
            clickReminder: data.requestBody.click_reminder,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_LEFT_CLICK", {
            displayMenu: data.requestBody.left_click,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_COMPANY_SWICTHING", {
            open_company_in_new_tab: data.requestBody.open_company_in_new_tab,
            storeData: context.rootState.storeData,
          });
          data.companies = context.rootGetters.getUserCompanies(data.userId);
          if (data.requestBody.designation) {
            context.commit("UPDATE_USER_DESIGNATION", data);
          }

          //if full_name key has some value then mutate local store
          if (data.requestBody.full_name) {
            let loggedInUser = context.rootGetters.getSelectedCompany.users.find(
              (user) => user._id == context.rootGetters.loggedInUserDetails._id
            );
            context.commit("UPDATE_USER_NAME", {
              full_name: data.requestBody.full_name,
              user: context.getters.getLoginUser,
              loggedInUser: loggedInUser,
            });
          }
        } else {
          reject(response);
        }
      });
    });
  },

  // Reset Password
  resetPassword: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
          debug.log(response.data);
          router.push("/");
        } else {
          reject(response);
        }
      };

      userAPI.resetPassword(
        body.obj.new_password,
        body.obj.confirm_new_password,
        body.token,
        done
      );
    });
  },
  //API call for set User new Password
  updateAdminPassword: (context, data) => {
    return new Promise((resolve) => {
      userAPI.updateAdminPassword(
        data.current_password,
        data.new_password,
        data.confirm_new_password,
        context.rootState.notifToken,
        (response) => {
          resolve(response);
        }
      );
    });
  },
  //API call for update User Name and Company Name
  updateAdminProfile: (context, data) => {
    return new Promise((resolve) => {
      userAPI.updateAdminProfile(data.requestBody, (response) => {
        if (response.status == 200) {
          resolve(response);
          data.companies = context.rootGetters.getUserCompanies(data.userId);
          debug.log("Name Changed");
          context.commit("UPDATE_USER_DESIGNATION", data);
          context.commit("UPDATE_ADMIN_NAME", {
            full_name: data.requestBody.full_name,
            user: context.getters.getLoginUser,
          });
          context.commit("UPDATE_MSG_INPUT", {
            msgSend: data.requestBody.msg_input_send,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_CLICK_REMINDER", {
            clickReminder: data.requestBody.click_reminder,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_LEFT_CLICK", {
            displayMenu: data.requestBody.left_click,
            storeData: context.rootState.storeData,
          });
          context.commit("UPDATE_COMPANY_SWICTHING", {
            open_company_in_new_tab: data.requestBody.open_company_in_new_tab,
            storeData: context.rootState.storeData,
          });
        }
      });
    });
  },
  //API call for update User Name and Company Name
  changeUserInfo: (context, data) => {
    return new Promise((resolve, reject) => {
      userAPI.changeUserInfo(data, (response) => {
        if (response.status == 200) {
          let companyUsers = context.rootGetters.getSelectedCompany.users;
          let loggedInUser = context.rootGetters.getLoggedInUserDetail;
          context.commit("CHANGE_USER_INFO", {
            data: data,
            companyUsers: companyUsers,
            loggedInUser: loggedInUser,
          });
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  // Remove Admin Profile Pictrue
  removeAdminProfilePic: (context, data) => {
    debug.log(data);
    userAPI.removeAdminProfilePic(
      data.profile_picture,
      data.two_fa,
      data.company_id,
      (response) => {
        if (response.status == 200) {
          debug.log(response);
        }
      }
    );
  },
  // Remove User Profile Pictrue
  removeUserProfilePic: (context, data) => {
    debug.log(data);
    userAPI.removeUserProfilePic(
      data.profile_picture,
      data.two_fa,
      data.company_id,
      (response) => {
        if (response.status == 200) {
          debug.log(response);
        }
      }
    );
  },

  //API call for  update User Profile Picture
  updateAdminPicture: (context, data) => {
    return new Promise((resolve) => {
      userAPI.updateAdminPicture(data, (response) => {
        if (response.status == 200) {
          resolve(response);
        }
      });
    });
  },
  // This function is responsible to update a user notification settings.
  // args:(data) i.e. in_app_notification, push_notification
  updateNotificationSetting(context, data) {
    let company_id = context.rootGetters.getSelectedCompany._id;
    let companyUser = context.getters.getUserFromCompanyUsers(
      company_id,
      context.rootGetters.getLoggedInUserDetail._id
    );
    context.commit("setNotificationSetting", {
      loginUser: companyUser,
      push_notification: data.push_notification,
      in_app_notification: data.in_app_notification,
      notification_sound: data.notification_sound,
    });
    return new Promise((resolve, reject) => {
      userAPI.updateNotificationSetting(
        data.in_app_notification,
        data.push_notification,
        data.notification_sound,
        data.email_notification,
        data.mobile_notification_delay,
        company_id,
        (response) => {
          if (response.status == 200) {
            context.commit("UPDATE_GLOBAL_SETTINGS", {
              data,
              storeData: context.rootState.storeData,
            });
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
  updateUserHideEmailStatus(context, data) {
    return new Promise((resolve, reject) => {
      userAPI.updateUserHideEmailStatus(data.hide_email, (response) => {
        if (response.status == 200) {
          resolve(response);
          context.commit("setUserEmailHideStatus", {
            loginUser: context.rootGetters.getLoggedInUserDetail,
            hide_email: data.hide_email,
          });
        } else {
          reject(response);
        }
      });
    });
  },
  setUsersPermission(context, data) {
    return new Promise((resolve) => {
      userAPI.setUsersPermission(
        data.permissions,
        data.user_id,
        data.company_id,

        (response) => {
          resolve(response);
        }
      );
    });
  },

  getPermissions: (context, data) => {
    return new Promise((resolve) => {
      userAPI.getPermissions(
        data.user_id,
        data.company_id,

        (res) => {
          if (res.status == 200) {
            context.commit("SET_PERMISSIONS", res.data);
          }
          resolve(res);
        }
      );
    });
  },

  allowAccess: (context, data) => {
    return new Promise((resolve) => {
      userAPI.setAllowAccess(
        data.user_id,
        data.allow_access,
        data.company_id,

        (response) => {
          if (response.status == 200) {
            context.commit("SET_ALLOW_ACCESS", {
              user: context.rootGetters.getSelectedCompany.users.find(
                (user) => user._id == data.user_id
              ),
              allowAccessStatus: data.allow_access,
            });
          }
          resolve(response);
        }
      );
    });
  },

  resendSetupEmail: (context, data) => {
    return new Promise((resolve, reject) => {
      userAPI.resendAccountSetupEmail(
        data.user_id,
        data.company_id,
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },

  /**
   * this function is responsible to get specific user's teams
   * args:(requestBody) i.e. company_id, user_id
   */
  findUsersTeamDetails: (context, requestBody) => {
    return new Promise((resolve, reject) => {
      userAPI.getUserTeamsDetails(
        requestBody.company_id,
        requestBody.user_id,
        (response) => {
          if (response.status == 200) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      );
    });
  },
  /**
   * this function is responsible to remove user from specific team
   * args:(body) i.e. team_id, user_ids array
   */
  removeMemberFromTeam: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      teamAPI.removeUsersInTeam(body.team_id, body.user_ids, done);
    });
  },
  /**
   * this function is responsible to add user in specific team
   * args:(body) i.e. team_id, user_ids array
   */
  addMemberToTeam: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      teamAPI.addUsersInTeam(body.team_id, body.user_ids, done);
    });
  },
  /**
   * this function is responsible to update intro for mobile
   */
  updateIntroForMobile: (context) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      userAPI.updateUserInfo(
        {
          skip_tutorial: true,
        },
        done
      );
    });
  },

  /**
    this function is responsible to update the user audio speed
   **/
  updateAudioSpeed: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      userAPI.updateUserAudioSpeed(body, done);
    });
  },

  /**
  this function is responsible to to update the user current timezone
   **/
  updateUserTimezone: (context, body) => {
    return new Promise((resolve, reject) => {
      var done = (response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      userAPI.currentUserTimezone(body, done);
    });
  },
};

const mutations = {
  CHANGE_USER_INFO: (state, payload) => {
    let user = payload.companyUsers.find(
      (user) => user._id == payload.data.user_id
    );
    if (user) {
      if (payload.data.email) user.email = payload.data.email;
      if (payload.data.full_name) user.name = payload.data.full_name;
      if (payload.data.designation) {
        user.designation = payload.data.designation;
      }
    }
    if (payload.data.user_id == payload.loggedInUser._id) {
      if (payload.data.email) payload.loggedInUser.email = payload.data.email;
      if (payload.data.full_name)
        payload.loggedInUser.name = payload.data.full_name;
    }
  },

  UPDATE_USER_DESIGNATION: (state, payload) => {
    payload.companies.forEach((company) =>
      company.users.forEach((user) => {
        if (user._id == payload.userId) {
          if ("custom_status" in payload.requestBody) {
            if (payload.requestBody.custom_status == "null") {
              payload.loggedInUser.custom_status = null;
              user.custom_status = null;
            } else {
              payload.loggedInUser.custom_status =
                payload.requestBody.custom_status;
              user.custom_status = payload.requestBody.custom_status;
            }
          }
          if ("designation" in payload.requestBody) {
            if (payload.requestBody.designation == "null") {
              payload.requestBody.designation = null;
              // payload.loggedInUser.designation = "";
              user.designation = "";
            } else {
              // payload.loggedInUser.designation = payload.requestBody.designation;
              user.designation = payload.requestBody.designation;
            }
          }
          if ("name" in payload.requestBody) {
            payload.loggedInUser.name = payload.requestBody.name;
            user.name = payload.requestBody.name;
          }
        }
      })
    );
  },
  UPDATE_USER_STATUS: (state, payload) => {
    if ("do_not_disturb" in payload.status)
      payload.loggedInUser.do_not_disturb = payload.status.do_not_disturb;
    else if ("status" in payload.status)
      payload.loggedInUser.status = payload.status.status;
    // set do not disturb timer
    if ("do_not_disturb_timer" in payload?.status) {
      if (payload.status.do_not_disturb_timer) {
        // Get the current date
        const date = new Date();

        // Add seconds to the current date
        date.setSeconds(
          date.getSeconds() + payload.status.do_not_disturb_timer
        );
        payload.loggedInUser.do_not_disturb_timer = date;
      } else {
        payload.loggedInUser.do_not_disturb_timer = null;
      }
    }
  },
  setNotificationSetting: (state, payload) => {
    if (payload.loginUser) {
      payload.loginUser.email_notification = payload.email_notification;
      payload.loginUser.push_notification = payload.push_notification;
      payload.loginUser.in_app_notification = payload.in_app_notification;
      payload.loginUser.notification_sound = payload.notification_sound;
    }
  },
  setUserEmailHideStatus: (state, payload) => {
    payload.loginUser.hide_email = payload.hide_email;
  },
  // This will add new member in team
  createNewMember: (state, payload) => {
    payload.getter.push(...payload.users);
  },

  //This will change data after getting api response
  createNewMemberResponse: (state, payload) => {
    for (let i = 0; i < payload.company_users.length; i++) {
      payload.new_users.find((item) => {
        if (item.email == payload.company_users[i].email) {
          Object.assign(payload.company_users[i], item);
          // payload.company_users[i] = item
        }
      });
    }

    for (let i = 0; i < payload.team_ids.length; i++) {
      payload.teams.filter((item) => {
        if (item._id == payload.team_ids[i]) {
          for (let user of payload.new_users) {
            item.users.push(user._id);
            // payload.teams.users.push()
          }
        }
      });
    }
  },
  // This mutation will archive user
  ARCHIVE_USER: (state, payload) => {
    //set user to archived
    //find that user  and set archived
    payload.users.find(
      (item) => item._id == payload.obj.userId
    ).archived = new Date().toLocaleString("sv-SE");
    //remove user from all teams
    payload.company.teams.forEach((team) => {
      team.users = team.users.filter(
        (teamUser) => teamUser != payload.obj.userId
      );
    });
    //remove user from all public channels
    payload.company.channels.forEach((channel) => {
      channel.users = channel.users.filter(
        (userId) => userId != payload.obj.userId
      );
    });

    // set activate in all direct channels
    payload.company.direct.forEach((directChannel) => {
      if (directChannel.users.includes(payload.obj.userId)) {
        directChannel.activate = false;
      }
    });
  },
  //This mutation will unarchive user
  UNARCHIVE_USER: (state, payload) => {
    //set archive null in company users
    payload.company.users.find(
      (user) => user._id == payload.user_id
    ).archived = null;
    //push user id in teams and their default public channels
    payload.teams.forEach((teamId) => {
      //push user id in teams
      payload.company.teams
        .find((team) => team._id == teamId)
        .users.push(payload.user_id);
      //push user id in default public channnels
      payload.company.channels.forEach((channel) => {
        if (channel.default && channel.team_id == teamId) {
          channel.users.push(payload.user_id);
        }
      });
    });
    //set activate status to true in direct channels which are with unarchived user
    payload.company.direct.forEach((directChannel) => {
      if (directChannel.users.includes(payload.user_id)) {
        directChannel.activate = true;
      }
    });
  },

  UPDATE_ADMIN_NAME: (state, payload) => {
    payload.user.name = payload.full_name;
  },

  UPDATE_USER_NAME: (state, payload) => {
    payload.user.name = payload.full_name;
    if (payload.loggedInUser) payload.loggedInUser.name = payload.full_name;
  },
  SET_GOOGLE_SECRET: (state, payload) =>
    (payload.rootGetters.getSelectedCompanyUsers.find(
      (user) => user.email == payload.user_email
    ).google_secret = false),

  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },

  SET_INTERVAL: (state, payload) => {
    state.interval = payload;
  },

  SET_ALLOW_ACCESS: (state, payload) => {
    payload.user.allow_access = payload.allowAccessStatus;
  },
  UPDATE_MSG_INPUT: (state, payload) => {
    payload.storeData.msg_input_send = payload.msgSend;
  },
  UPDATE_CLICK_REMINDER: (state, payload) => {
    payload.storeData.click_reminder = payload.clickReminder;
  },

  //update global settings of user
  UPDATE_GLOBAL_SETTINGS: (state, payload) => {
    if ("mobile_notification_delay" in payload.data) {
      payload.storeData.mobile_notification_delay =
        payload.data.mobile_notification_delay;
    }
  },
  UPDATE_PROFILE_DRAWER_STATE: (state, payload) => {
    state.isOpenProfileDrawer = payload;
  },
  UPDATE_PROFILE_DRAWER_DATA: (state, payload) => {
    state.userProfileData = payload;
  },
  UPDATE_LEFT_CLICK: (state, payload) => {
    payload.storeData.left_click = payload.displayMenu;
  },
  UPDATE_HELP_DRAWER_STATE: (state, payload) => {
    state.openHelpDrawer = payload;
  },
  UPDATE_MESSAGE_REMINDER_DRAWER_STATE: (state, payload) => {
    state.openMessageReminderDrawer = payload;
  },
  UPDATE_MESSAGE_REPLY_DRAWER_STATE: (state, payload) => {
    state.openReplyDrawer = payload;
  },
  UPDATE_CUSTOM_STATUS_EXPIRATION: (state, payload) => {
    if (payload?.requestBody?.status_expire_at) {
      // Get the current date
      const date = new Date();

      // Add seconds to the current date
      date.setSeconds(date.getSeconds() + payload.requestBody.status_expire_at);
      payload.loggedInUser.status_expire_at = date;
    } else {
      payload.loggedInUser.status_expire_at = null;
    }
  },
  UPDATE_COMPANY_SWICTHING: (state, payload) => {
    payload.storeData.open_company_in_new_tab = payload.open_company_in_new_tab;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
