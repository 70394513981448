/**
System: PF Chat
Developer:  Saud Saleem
Organization: Programmer Force
Purpose: This file is responsible for handling all the store functionality of message reminder, It handle set Reminder and Delete, Update,Complete Reminder in store.
 */
import messageAPI from "@/api/messageAPI";
import Vue from "vue";
var messageReminder = {
    getters: {
        /**
         * this getter will get all reminded messages array of currently login user from storeData.
         */
        getReminderMessages: (state, getters, rootState, rootGetters) => rootGetters.getSelectedCompany.reminders,
    },
    actions: {

        /**
         * this function is responsible to add reminder on specifc message.
         * args :(requestBody) company_id, team_id, channel_id, message_id
         */
        remindMessage(context, requestBody) {
            return new Promise((resolve, reject) => {
                //store reference of previous clicked channel if user immediatly changed channel after message reminder then update his/her message status after API response
                let previousMessages = context.rootGetters.getCurrentMessages;
                let selectedComapny = context.rootGetters.getSelectedCompany;
                let selectedChannelId = context.rootState.selectedChannel._id;
                let reminderMessages = context.getters.getReminderMessages
                var done = (response) => {

                    if (response.status == 201) {
                        resolve(response)
                            //get message index from store Data
                        let index = context.rootGetters.getSendMessageIndex(
                            requestBody.message_id,
                            response.data.message_id,
                            previousMessages
                        );
                        if (index > -1) {
                            context.commit("addMsgReminder", {
                                currentMessages: previousMessages,
                                index,
                                apiData: response.data,
                                selectedComapny,
                                selectedChannelId,
                                reminderMessages
                            })
                        }
                    } else {
                        reject(response)
                    }
                };
                //call api
                messageAPI.remindMessageApi(
                    requestBody,
                    done
                );
            })
        },
        /**
         * this function is responsible to update reminder on specifc message.
         * args :(requestBody) reminder_id
         */
        updateRemindMessage(context, requestBody) {
            //store reference of previous clicked channel if user immediatly changed channel after message reminder then update his/her message status after API response
            let previousMessages = context.rootGetters.getCurrentMessages;
            let selectedComapny = context.rootGetters.getSelectedCompany;
            let selectedChannelId = context.rootState.selectedChannel._id;
            let reminderMessages = context.getters.getReminderMessages
            var done = (response) => {
                if (response.status == 200) {
                    //get message index from store Data
                    let index = context.rootGetters.getSendMessageIndex(
                        'temp-123456',
                        response.data.message_id,
                        previousMessages
                    );
                    if (index > -1) {
                        context.commit("updateMsgReminder", {
                            currentMessages: previousMessages,
                            index,
                            apiData: response.data,
                            selectedComapny,
                            selectedChannelId,
                            preReminderId: requestBody.reminder_id,
                            reminderMessages: reminderMessages

                        })
                    }
                    context.commit('updateAPIReminderMessages', {
                        currentMessages: previousMessages,
                        index,
                        apiData: response.data,
                        selectedComapny,
                        selectedChannelId,
                        preReminderId: requestBody.reminder_id,
                        reminderMessages: reminderMessages
                    })

                }
            };
            //call api
            messageAPI.updateRemindMessage(
                requestBody,
                done
            );
        },

        /**
         * this function is responsible for getting all message reminders from API and store response to store
         * args :(requestBody) 
         */
        getRemindersByAPI(context, requestBody) {
            return new Promise((resolve, reject) => {
                var done = (response) => {
                    if (response.status == 200) {
                        context.commit('setReminders', {
                            reminders: response.data.reminders,
                            company: context.rootGetters.getSelectedCompany
                        })
                        resolve(response)
                    } else {
                        reject(response)
                    }

                }
                messageAPI.getRemindersByAPI(requestBody, done);
            })


        },
        /**
         * this function is responsible for removing all message reminders from store.
         * args :(requestBody) 
         */
        removeReminderMessages(context) {
            let reminderMessages = context.getters.getReminderMessages;
            context.commit('RemoveReminderMessages', { reminders: reminderMessages })

        },
        /**
         * this function is responsible for mark complete  message reminder 
         * args :(requestBody) message_id
         */
        completeMessageRemind({ getters, commit }, requestBody) {
            let previousMessages = getters.getCurrentMessages;
            var done = (response) => {
                if (response.status == 200) {
                    //get message index from store Data
                    let index = getters.getSendMessageIndex(
                        requestBody.message_id,
                        null,
                        previousMessages
                    );
                    if (index > -1) {
                        commit("completeMessageRemind", {
                            currentMessages: previousMessages,
                            index,

                        })
                    }
                }
            };
            messageAPI.completeMessageRemind(requestBody, done);
        },
        /**
         * this function is responsible to delete reminder on specifc message.
         * args :(requestBody) message_id, reminder_id
         */
        deleteMessageRemind({ getters, rootGetters, commit }, requestBody) {
            //store reference of previous clicked channel if user immediatly changed channel after message reminder then update his/her message status after API response
            let previousMessages = getters.getCurrentMessages;
            let companyReminderMsgs = rootGetters.getSelectedCompany.reminders;
            var done = (response) => {
                if (response.status == 200) {
                    //get message index from store Data
                    let index = getters.getSendMessageIndex(
                        requestBody.message_id,
                        null,
                        previousMessages
                    );
                    if (index > -1) {
                        commit("deleteMsgReminder", {
                            currentMessages: previousMessages,
                            index,
                            reminder_id: requestBody.reminder_id,
                            logInUserId: getters.loggedInUserDetails._id,
                            companyReminderMsgs
                        })
                    }
                    commit("deleteAPIMsgReminder", {
                        currentMessages: previousMessages,
                        index,
                        reminder_id: requestBody.reminder_id,
                        logInUserId: getters.loggedInUserDetails._id,
                        companyReminderMsgs
                    })
                }
            };
            messageAPI.deleteRemindMsgApi(requestBody, done);
        },
    },
    mutations: {
        RemoveReminderMessages: (state, payload) => {
            payload.reminders = []
        },
        setReminders: (state, payload) => {
            payload.company.reminders = payload.reminders
        },
        /**
         * this mutation will push object of user and time on which reminder is set.
         * args:((state, payload))
         */
        addMsgReminder: (state, payload) => {
            //make reminder object 
            let reminder = {
                reminder_id: payload.apiData._id,
                user_id: payload.apiData.user_id,
                remind_at: payload.apiData.send_reminder_at,
                title: payload.apiData.title
            };
            //add object in message reminded_by array
            payload.currentMessages[payload.index].reminded_by.push(reminder);
            Vue.set(payload.reminderMessages, payload.reminderMessages.length, payload.apiData)


        },
        updateAPIReminderMessages: (state, payload) => {
            let reminderIndex = payload.reminderMessages.findIndex(remind => payload.preReminderId == remind._id)
            if (reminderIndex > -1) {
                Vue.set(payload.reminderMessages, reminderIndex, payload.apiData)
            }
        },
        updateMsgReminder: (state, payload) => {
            //make reminder object 
            let reminder = {
                reminder_id: payload.apiData._id,
                user_id: payload.apiData.user_id,
                remind_at: payload.apiData.send_reminder_at
            };
            //add object in message reminded_by array

            let index = payload.currentMessages[payload.index].reminded_by.findIndex(remind => payload.preReminderId == remind.reminder_id)
            if (index > -1) {
                Vue.set(payload.currentMessages[payload.index].reminded_by, index, reminder)
            }
        },
        completeMessageRemind: (state, payload) => {
            payload.currentMessages.splice(payload.index, 1)
        },


        deleteAPIMsgReminder: (state, payload) => {
            let remindIndex = payload.companyReminderMsgs.findIndex(remindMsg => remindMsg._id == payload.reminder_id);
            if (remindIndex > -1) {
                payload.companyReminderMsgs[remindIndex].completed = true
            }
        },
        /**
         * this mutation will delete remiders.
         * args:((state, payload))
         */
        deleteMsgReminder: (state, payload) => {
            //find reminder index
            let remindIndex = payload.currentMessages[payload.index].reminded_by.findIndex(remind => remind.reminder_id == payload.reminder_id && remind.user_id == payload.logInUserId);
            //if index is find then remove reminder
            if (remindIndex > -1) {
                //remove reminder
                payload.currentMessages[payload.index].reminded_by.splice(remindIndex, 1)
            }
            //remove from company reminder messages array



        },
    }
}
export default messageReminder;