import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/styles.css";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import "highlight.js/styles/vs2015.css";
import "./service/uploadFiles";
// debugger for console logs to display or not
import myDebugger from "./console";

//global stylesheet
import "./assets/css/styles.css";

/* Resize Observer used to make message input height
   dynamic and move message area scroll along with message input */
import "vue-resize/dist/vue-resize.css";
import VueResize from "vue-resize";
import markdown from "markdown-it";
import firebase from "firebase/app";
import "firebase/messaging";
import Vuetify from "vuetify/lib";
import "./registerServiceWorker";
import "./ResumableJs/resumable.js";
import { TimeAgo } from "vue2-timeago";
import fixWebmDuration from "./service/fixWebmDuration";

Vue.use(VueResize);
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.component("time-ago", TimeAgo);

//event bus for component comunication
export const eventBus = new Vue();
export const eventBusReply = new Vue();
Vue.config.productionTip = false;
Vuetify.config.silent = true;
const socket = io({
  path: "/socket/socket.io/",
  transports: ["websocket"],
  upgrade: false, 
  perMessageDeflate: false,
});


console.log("hello")
Vue.use(VueSocketIOExt, socket,  {
  store,
});
console.log("this is debouncing branch")
Object.defineProperty(Vue.prototype, "$markdown", {
  value: new markdown({
    html: true, // Enable HTML tags in source
    xhtmlOut: false, // Use '/' to close single tags (<br />).
    // This is only for full CommonMark compatibility.
    breaks: true, // Convert '\n' in paragraphs into <br>
    langPrefix: "language-", // CSS language prefix for fenced blocks. Can be
    // useful for external highlighters.
    linkify: true, // Autoconvert URL-like text to links

    // Enable some language-neutral replacement + quotes beautification
    typographer: false,

    // Double + single quotes replacement pairs, when typographer enabled,
    // and smartquotes on. Could be either a String or an Array.
    //
    // For example, you can use '«»„“' for Russian, '„“‚‘' for German,
    // and ['«\xA0', '\xA0»', '‹\xA0', '\xA0›'] for French (including nbsp).
    quotes: "“”‘’",

    // Highlighter function. Should return escaped HTML,
    // or '' if the source string is not changed and should be escaped externally.
    // If result starts with <pre... internal wrapper is skipped.
    highlight: function(/*str, lang*/) {
      return "";
    },
  }),
});

firebase.initializeApp({
  apiKey: "AIzaSyBJ8D4keE7EtjqDMFkWKVqUHsrU5w4pCv0",
  authDomain: "pfchat-b0f65.firebaseapp.com",
  projectId: "pfchat-b0f65",
  storageBucket: "pfchat-b0f65.appspot.com",
  messagingSenderId: "1066162695414",
  appId: "1:1066162695414:web:de3bd47b4d3a1eff9710d7",
  measurementId: "G-K75HENZCWH",
});

if ("serviceWorker" in navigator) {
  // Supported!
  navigator.serviceWorker.onmessage = (event) => {
    if (
      event.data &&
      event.data.data &&
      event.data.data.message_body &&
      Object.keys(JSON.parse(event.data.data.message_body)).length
    ) {
      let messageData = JSON.parse(event.data.data.message_body);
      if (messageData?.event_name) {
        store.dispatch(messageData.event_name, messageData);
      } else if (messageData?.message_body?.event_name) {
        store.dispatch(
          messageData.message_body.event_name,
          messageData.message_body
        );
      }
    }

    if (event.data.link) {
      let link = event.data.link.split("/");
      if (link[3] != store.state.selectedCompanyId && link[4] == "null") {
        let company = store.getters.getCompanies.find(
          (company) => company._id == link[3]
        );
        link[4] = company.teams.find(
          (team) => team.joined && team.archived == null
        )._id;
      } else if (link[4] == "null") {
        link[4] = store.state.selectedTeamId;
      }
      // open company in new tab
      if (
        store.state.storeData?.open_company_in_new_tab &&
        event.data.isOpenNewTab
      ) {
        window.open(
          window.location.origin +
            "/" +
            link[3] +
            "/" +
            link[4] +
            "/" +
            link[5],
          "_balnk" + Math.floor(Math.random() * 90000) + 10000
        );
      } else {
        //close recent messages drawer
        store.commit("UNREAD_MESSAGE_DRAWER", false);
        router
          .push({
            name: "home",
            params: {
              companyId: link[3],
              teamId: link[4],
              channelId: link[5],
            },
          })
          .catch(() => {});
      }
    }
  };
}
// Prototypes
Vue.prototype.$debug = myDebugger;
Vue.prototype.$fixWebmDuration = fixWebmDuration;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
