/* This file served as an API Request queue, so actually this file put all api 
requests in queue and send response back to action in case of success and failure.
This file also make retry for message */

import axios from "axios";
import store from "@/store/index";
import debug from "@/console";
import router from "@/router/index";
import axiosRetry from "axios-retry";
//application url
const baseUrl = process.env.VUE_APP_BASE_URL;

//Customized axios request
var customAxios;

const RequestQueue = {
  /**
   * Make axios post request and send response back
   *
   * @param {String} endpoint The api url to make call
   * @param {String} token The secret token for the api call
   * @param {Object} body The request body
   * @param {Function} success the callback on success failure
   * @param {Object} confix the axios config object
   *
   * @return {Promise} success callback
   */
  post: async (endpoint, token, body, success, config) => {
    //make temp id for request
    let id = Date.now();
    //make request queue object
    let request = {
      id: id,
      endpoint: endpoint,
      token: token,
      body: body,
      config: config,
    };
    //push to request queue
    store.commit("pushRequest", request);
    //if offline then request is already store, so return back
    if (!navigator.onLine) {
      return;
    }

    //check request body exist or simple body exist(message case)
    let requestBody = body?.requestBody ? body.requestBody : body;

    //if no token is provided
    if (!token) {
      customAxios = axios;
      await customAxios
        .post(`${baseUrl}/${endpoint}`, requestBody)
        .then((res) => {
          if (res.data && res.data.version) {
            store.dispatch("checkAppVersion", res.data.version);
          }
          debug.log("test", res);
          success(res);
          store.commit("popRequest", request.id);
        })
        .catch((err) => {
          if (err.response) {
            store.dispatch("checkAppVersion", err.response.data.version);
          }
          store.commit("popRequest", request.id);
          //if token is unauthorized
          if (err.response && err.response.status == 401) {
            RequestQueue.unauthorizedToken();
          } else {
            success(err);
          }
        });
    }

    //if token is provided
    else {
      //extra axios config in case of file for file upload progress
      config
        ? (customAxios = axios.create({
            headers: {
              Token: token,
            },
            onUploadProgress: function(progressEvent) {
              config.progress = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          }))
        : (customAxios = axios.create({
            headers: {
              Token: token,
            },
          }));

      //check for message create to make retry
      if (
        endpoint == "message/create" ||
        endpoint == "message/sendGuestMessage"
      ) {
        // Exponential back-off retry delay between requests
        axiosRetry(customAxios, {
          //no of retries
          retries: 10,
          //delay in each retry
          retryDelay: (retryCount, error) => {
            //if retry less then 4, make request after every 5seconds
            if (retryCount <= 4) {
              const delay = retryCount * 5000;
              const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
              return delay + randomSum;
            }
            //after 4 retries, make request exponentially
            else {
              const delay = Math.pow(2, retryCount) * 100;
              const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
              return delay + randomSum;
            }
          },
          //condition to go for retry
          retryCondition: (error) => {
            return (
              error.code !== "ECONNABORTED" &&
              (!error.response ||
                (error.response.status >= 500 &&
                  error.response.status <= 599) ||
                error.response.status == 429)
            );
          },
        });
      }

      //nmake call
      await customAxios
        .post(`${baseUrl}/${endpoint}`, requestBody)
        .then((res) => {
          if (res.data && res.data.version) {
            store.dispatch("checkAppVersion", res.data.version);
          }

          debug.log("test", res);
          success(res);
          store.commit("popRequest", request.id);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.version) {
            store.dispatch("checkAppVersion", err.response.data.version);
          }

          //if token is unauthorized
          if (err.response && err.response.status == 401) {
            RequestQueue.unauthorizedToken();
          } else if (
            err.response &&
            (err.response.status == 409 ||
              err.response.status == 400 ||
              err.response.status == 405)
          ) {
            store.commit("popRequest", request.id);
            success(err);
          } else {
            //update queue and message view when max retries are done
            if (
              endpoint == "message/create" ||
              endpoint == "message/sendGuestMessage"
            ) {
              store.commit("updateQueue", { id: request.id, maxRetry: true });
              store.commit("updateMessages", {
                request: request,
                maxRetry: true,
              });
              // success(request);
            } else {
              success(err);
            }
          }
        });
    }
  },

  /**
   * Retry request in queue after internet connect and page load
   *
   *
   * @return {void}
   */
  startQueue: async () => {
    let promises = [];
    for (let request of store.state.queue) {
      //retry request only for max retry false with message
      if (
        (request.endpoint == "message/create" ||
          request.endpoint == "message/sendGuestMessage") &&
        request.body.messageBody.maxRetry == false
      ) {
        promises.push(RequestQueue.retry(request));
      } else {
        store.commit("popRequest", request.id);
      }
      //retry request other then message
      // else if (
      //   request.endpoint != "message/create" &&
      //   request.endpoint != "message/sendGuestMessage"
      // ) {
      //   promises.push(RequestQueue.retry(request));
      // }
    }

    //wait for calls to completed
    await Promise.allSettled(promises).then(async () => {
      //remove from queue
      store.state.queue.forEach((queue) => {
        if (
          (queue.endpoint == "message/create" ||
            queue.endpoint == "message/sendGuestMessage") &&
          !queue?.body?.messageBody.maxRetry
        ) {
          store.commit("popRequest", queue.id);
        } else if (
          queue.endpoint != "message/create" &&
          queue.endpoint != "message/sendGuestMessage"
        ) {
          store.commit("popRequest", queue.id);
        }
      });
      //retry for second attempt
    });
  },

  /**
   * This function is responsible to disconnect everything of 401 unauthorized request
   *
   * @return {void}
   */
  unauthorizedToken: () => {
    //disconnect socket
    store.dispatch("disconnectSocket");
    //clear all storages
    store.commit("logout");
    //resolve route
    let login = router.resolve({
      name: "login",
      params: "/",
    });
    //move to login page
    window.location.assign(login.href);
  },

  /**
   * Make axios post request and send response back
   *
   * @param {Object} request the object for request
   * @return {Promise} success callback
   */
  retry: (request) => {
    customAxios = axios.create({
      headers: {
        Token: request.token,
      },
    });
    //call API and remove request from queue with request's id
    return new Promise((resolve, reject) => {
      let requestBody = request.body?.requestBody
        ? request.body.requestBody
        : request.body;
      if (
        request.endpoint == "message/create" ||
        request.endpoint == "message/sendGuestMessage"
      ) {
        axiosRetry(customAxios, {
          retries: 10,
          retryDelay: (retryCount, error) => {
            if (retryCount <= 4) {
              const delay = retryCount * 5000;
              const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
              return delay + randomSum;
            } else {
              const delay = Math.pow(2, retryCount) * 100;
              const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
              return delay + randomSum;
            }
          },
          retryCondition: (error) => {
            return (
              error.code !== "ECONNABORTED" &&
              (!error.response ||
                (error.response.status >= 500 &&
                  error.response.status <= 599) ||
                error.response.status == 429)
            );
          },
        });
      }

      customAxios
        .post(`${baseUrl}/${request.endpoint}`, requestBody)
        .then((res) => {
          store.commit("popRequest", request.id);
          debug.log(res);
          resolve(res);
        })
        .catch((err) => {
          debug.log(err);
          //if token is unauthorized
          if (err.response && err.response.status == 401) {
            RequestQueue.unauthorizedToken();
          } else if (
            err.response &&
            (err.response.status == 409 ||
              err.response.status == 400 ||
              err.response.status == 405)
          ) {
            store.commit("popRequest", request.id);
          } else {
            if (
              request.endpoint == "message/create" ||
              request.endpoint == "message/sendGuestMessage"
            ) {
              store.commit("updateQueue", { id: request.id, maxRetry: true });
              store.commit("updateMessages", {
                request: request,
                maxRetry: true,
              });
              // success(request);
            }
          }
        });
    });
  },
};

export default RequestQueue;
